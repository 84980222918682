import React from "react";
import { Container, Row } from "react-bootstrap";
import ServicesHeader from "../../../components/NavigationHeader/services-header";
import NotFromPatna from "../../../components/NotFromPatna/notFromPatna";
import { theme } from "../../../constants/theme";

export default function FingerJointReplacementView() {
  return (
    <div>
      <ServicesHeader title="Finger Joint Replacement" />
      <div className="service-list-container">
        <Container>
          <Row className="row-1">
            <div className="text-block">
              <h3
                className="heading-data"
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.headingText,
                  textAlign: "center",
                  marginBottom: 45,
                }}
              >
                About Finger Joint Replacement
              </h3>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                This is performed for small joints of the finger when they
                become arthritic and painful. Joint replacement preserves the
                mobility of the finger. The damaged cartilage and bone is
                removed and replaced with high quality durable silicon or metal
                and plastic joints. This can be performed by numbing the finger
                or the wrist joint.
              </p>

              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What is the cost of finger joint replacement Surgery?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Dr Das exclusively utilises US or European imported finger joint
                replacement implants with an excellent published track record.
                He has best surgical outcome with a very high patient
                satisfaction rate. This is provided at an affordable package
                cost which includes surgery, hospital stay, implants and
                physiotherapy. Please feel free to contact us for to know about
                the finger joint replacement package.
              </p>
            </div>
            <NotFromPatna />
          </Row>
        </Container>
      </div>
    </div>
  );
}
