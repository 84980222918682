const base = {
  fontFamily: {
    light: "PlusJakartaDisplay-Light",
    regular: "PlusJakartaDisplay-Regular",
    medium: "PlusJakartaDisplay-Medium",
    bold: "PlusJakartaDisplay-Bold",
  },
};

const theme = {
  name: "default",
  ...base,
  bgColor: {},
  color: {
    white: "#ffffff",
    offWhite: "#fafafa",
    black: "#000000",
    darkGray: "#4D4D4D",
    red: "#ff0000",
    yellow: "#ffe57f",
    green: "#2eb840",
    gray: "#e8e8e8",
    lightGray: "#f0f0f0",
    mildGray: "#bcbcbc",
    silver: "#e2e2e3",
    transparent: "transparent",
    primary: "#4053fd",
    primaryDisabled: "#5D6DFD",
    primaryLightest: "rgba(196,196,196,0.1)",
    secondary: "#1A181B",
    separator: "#48464B",
    divider: "#d9d9d9",
    darkText: "#2d2d2d",
    headingText: "#212121",
    lightText: "#64607D",
  },
};

export { theme };
