import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import NavigationHeader from "../../components/NavigationHeader/navigation-header";
import AboutPicture from "../../assets/images/about.png";
import AboutPicture2 from "../../assets/images/about2.png";
import { theme } from "../../constants/theme";
import "./aboutView.css";
import AboutAccordion from "../../components/AboutAccordion/aboutAccordion";

export default function AboutView() {
  return (
    <div>
      <NavigationHeader title="About the Doctor" />
      <div className="about-container">
        <Container>
          <Row className="row-1">
            <Col sm={12} md={12} lg={5} xl={4}>
              <div className="image-block">
                <img src={AboutPicture} alt="about" className="about-img" />
              </div>
            </Col>
            <Col sm={12} md={12} lg={7} xl={8}>
              <div className="text-block">
                <h3
                  className="heading-data"
                  style={{
                    fontFamily: theme.fontFamily.bold,
                    color: theme.color.headingText,
                  }}
                >
                  About Dr Abhishek Kumar Das
                </h3>
                <p
                  className="desc-text"
                  style={{
                    fontFamily: theme.fontFamily.light,
                    color: theme.color.lightText,
                  }}
                >
                  Dr Abhishek Kumar Das is a Senior Orthopaedic Consultant at
                  Big Apollo Spectra Hospital, Patna. His name is synonymous
                  with clinical excellence and patient centred approach. He
                  passed MBBS from PMCH Patna and MS Orthopaedics from Lokmanya
                  Tilak Municipal Medical College (LTMMC) Sion, Mumbai followed
                  by DNB Orthopaedics. He has extensively trained in Orthopaedic
                  subspecialities in UK with knee and Senior upper limb
                  fellowships at world renowned Centre of Excellence
                  Wrightington Hospital, UK. During his pursuit for academic
                  excellence, he achieved Intercollegiate MRCS from RCPSG,
                  Glasgow, MCh from Edgehill University, UK and Intercollegiate
                  FRCS (Trauma & Orth) UK. This enabled him to attain the
                  coveted CESR, Trauma & Orth, UK. He has also completed
                  European Board Fellowship in Orthopaedics, FEBOT, Berlin,
                  Germany. Dr Das has a passion for Cartilage and Joint
                  preservation and management of sports injuries. He has trained
                  alongside some of the best surgeons across the globe in
                  Keyhole/ Arthroscopic surgery of knee, shoulder, elbow and
                  ankle joints. He has tremendous expertise in Arthroscopy and
                  Sports Medicine which includes high end shoulder and Knee
                  arthroscopy viz capsular release, labral repair, rotator cuff
                  repair, ACL & PCL Reconstruction, meniscus repair and
                  microfracture. He is a one stop solution for sports injury
                  including Arthroscopy of elbow and ankle.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="row-2">
            <Col sm={12} md={12} lg={7} xl={8}>
              <div className="text-block">
                <p
                  className="desc-text"
                  style={{
                    fontFamily: theme.fontFamily.light,
                    color: theme.color.lightText,
                  }}
                >
                  He has over 15 years of experience in Joint replacement
                  surgery. Dr Das has comprehensive expertise in Anatomic Total
                  or Reverse Geometry shoulder replacement, Total Elbow
                  Replacement, partial and total knee replacement, cemented and
                  uncemented hip replacement surgery. Where primary/initial
                  knee, shoulder and hip joint replacements have failed, he can
                  provide patient customised treatment in the form of revision
                  joint replacement. Dr Das is proficient in all aspects of
                  trauma management which includes periprothetic fractures
                  (fractures around joint replacement), polytrauma, complex &
                  periarticular fracture. Dr Das has been actively involved in
                  Orthopaedic Research. He has published extensively with more
                  than 10 articles in peer reviewed high impact indexed national
                  and international journals. He is Editor of Asteroid Journal
                  of Orthopaedic Research and Reviewer for Journal of Clinical
                  Orthopaedics and Trauma and BMC Musculoskeletal Disorders. In
                  order to improve patient care and safety, he has been actively
                  involved in Clinical Audits. He is a leading Orthopaedic
                  Surgeon with a vision to provide top quality treatment and
                  patient care at par with western countries at affordable
                  prices.
                </p>
              </div>
            </Col>
            <Col sm={12} md={12} lg={5} xl={4}>
              <div className="image-block">
                <img src={AboutPicture2} alt="about" className="about-img" />
              </div>
            </Col>
          </Row>
          <div className="accordion-section">
            <h3
              className="heading-title"
              style={{
                fontFamily: theme.fontFamily.bold,
                color: theme.color.headingText,
              }}
            >
              Achievements and Contribution
            </h3>
            <AboutAccordion />
          </div>
        </Container>
      </div>
    </div>
  );
}
