import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { theme } from "../../constants/theme";
import { menuItems } from "./MenuItems";
import "./navigationStyles.css";

export default function NavigationMenu({ toggle }) {
  let activeStyle = {
    fontFamily: theme.fontFamily.medium,
    color: theme.color.primary,
    borderBottomWidth: 2,
    borderBottomStyle: "solid",
    borderBottomColor: theme.color.primary,
  };

  return (
    <>
      <div className="navigationList">
        {menuItems.map((e) => (
          <>
            <NavLink
              // className="navigationItems link-underline"
              className={({ isActive }) =>
                isActive ? "navigationItems" : "navigationItems link-underline"
              }
              key={e.id}
              style={({ isActive }) =>
                isActive
                  ? activeStyle
                  : {
                      fontFamily: theme.fontFamily.regular,
                      color: theme.color.black,
                    }
              }
              to={e.path}
            >
              {e.title}
            </NavLink>
          </>
        ))}
      </div>
    </>
  );
}
