import React from "react";
import { Container, Row } from "react-bootstrap";
import ServicesHeader from "../../../components/NavigationHeader/services-header";
import NotFromPatna from "../../../components/NotFromPatna/notFromPatna";
import { theme } from "../../../constants/theme";

export default function ACLSurgeryView() {
  return (
    <div>
      <ServicesHeader title="ACL Surgery" />
      <div className="service-list-container">
        <Container>
          <Row className="row-1">
            <div className="text-block">
              <h3
                className="heading-data"
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.headingText,
                  textAlign: "center",
                  marginBottom: 45,
                }}
              >
                About ACL Surgery
              </h3>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What is ACL?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                ACL means Anterior Cruciate Ligament. The ACL is among the group
                of ligaments inside the knee joint which connect the leg bone
                and the thigh bone. The ACL runs through the knee’s centre, and
                crosses the posterior cruciate ligament (PCL) diagonally.
                Together with the PCL, the ACL provides an important function –
                controlling the knee as it moves forwards or backwards. The most
                important function of the ACL is controlling the stability of
                the knee rotationally as you twist and turn such as in sports
                like cricket, running, football, rugby, basketball and so on. It
                is not uncommon for ACL injuries to occur concurrently with
                another torn ligament or tissue in the knee joint.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Is it necessary to have ACL Reconstruction?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                In general, a person is a candidate for an ACL reconstruction if
                their knee is not stable. If you feel your knee gives way or you
                fall down or have lost confidence then ACL Reconstruction
                surgery is helpful. ACL reconstruction offers the dual benefits
                of stabilizing the knee, while also preventing the knee
                cartilage from getting more injuries to it every time the knee
                gives way. Hence, it has a role in preventing future arthritis.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What happens during ACL Reconstruction Surgery?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Arthroscopy or Keyhole surgery is done to visualise and then
                reconstruct the ACL. For this reconstruction a graft tissue is
                needed which is taken from your same knee or in some cases like
                redo surgery from other knee using a small incision. Two most
                common grafts are hamstring and patella (kneecap) tendon. This
                is then weaved and arthroscopically fixed inside the knee using
                special implants. The surgery usually takes one and half hours
                to compete. This can be a day case or you might have to stay in
                hospital for a few days depending upon your rehab and
                physiotherapy which is supervised by trained physiotherapists.
                Usually patients are able to walk on the same day.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What is the cost of ACL Reconstruction Surgery?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Dr Das utilises US or European imported ACL Reconstruction
                implants with an excellent published track record. He has best
                surgical outcome with a very high patient satisfaction rate.
                This is provided at an affordable package cost which includes
                surgery, hospital stay, implants and physiotherapy. Please feel
                free to contact us to know about the ACL package.
              </p>
            </div>
            <NotFromPatna />
          </Row>
        </Container>
      </div>
    </div>
  );
}
