import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  FaFacebookSquare,
  FaInstagram,
  FaLinkedinIn,
  FaMapMarkerAlt,
  FaPhone,
  FaSkype,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import Logo from "../../assets/images/logo.svg";
import { theme } from "../../constants/theme";
import "./footer.css";

export default function Footer() {
  return (
    <div
      className="footer-container"
      style={{
        backgroundColor: theme.color.primaryLightest,
      }}
    >
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <div className="logo-wrapper">
              <img src={Logo} alt="logo" />
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.darkText,
                  fontSize: 20,
                }}
                className="logoText"
              >
                Shoulder Knee Specialist
              </p>
            </div>
          </Col>
          <Col sm={12} md={6}>
            <div className="col-center">
              <div className="mb20">
                <div className="row-center-between">
                  <a
                    href="https://goo.gl/maps/eWp3ct6Ty72SHeNPA"
                    target="_blank"
                    className="row-center-between"
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <FaMapMarkerAlt color={theme.color.primary} size={16} />
                    <p
                      className="footer-data"
                      style={{
                        fontFamily: theme.fontFamily.regular,
                        color: theme.color.darkText,
                      }}
                    >
                      Sushrut Clinic, Vivek Nagar Society, Beside Reliance
                      Trends, Atal Path, East Boring Canal Road, Patna-800001
                    </p>
                  </a>
                </div>
              </div>
              <div className="mb-20">
                <div className="row-center-between">
                  <div className="row-center-between">
                    <FaPhone color={theme.color.primary} size={16} />
                    <a
                      className="footer-data"
                      style={{
                        fontFamily: theme.fontFamily.regular,
                        color: theme.color.darkText,
                        textDecoration: "none",
                      }}
                      href="tel:+919523432866"
                    >
                      +91 9523432866
                    </a>
                  </div>
                </div>
              </div>
              <div className="social-media-view">
                <div className="row-center-between">
                  <p
                    className="social-media-label"
                    style={{
                      fontFamily: theme.fontFamily.light,
                      color: theme.color.darkText,
                    }}
                  >
                    Social Media :
                  </p>
                  <a
                    href="https://www.linkedin.com/in/abhishek-das-1b09a03a"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <button className="social-media-btn">
                      <FaLinkedinIn color={theme.color.primary} size={16} />
                    </button>
                  </a>
                  <a
                    href="https://twitter.com/DRDASABHI"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <button className="social-media-btn">
                      <FaTwitter color={theme.color.primary} size={16} />
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div
        className="separator"
        style={{ backgroundColor: theme.color.primary }}
      />
      <div className="mt24">
        <p
          className="copyright"
          style={{
            fontFamily: theme.fontFamily.light,
            color: theme.color.darkText,
          }}
        >
          Copyright © 2022 • Shoulder Knee Specialist
        </p>
      </div>
    </div>
  );
}
