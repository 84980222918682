import React from "react";
import NavigationHeader from "../../components/NavigationHeader/navigation-header";
import "../GalleryView/galleryView.css";
import { videoData } from "./video-data";

export default function VideosView() {
  return (
    <div>
      <NavigationHeader title="Videos" />
      <div className="content">
        <div className="mobile-testimonial">
          <div className="mobile-gallery">
            {videoData.map((e) => (
              <div key={e.id}>
                <iframe
                  width="95%"
                  height="315"
                  src={e.link}
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  className="mobile-video"
                ></iframe>
              </div>
            ))}
          </div>
        </div>
        <div className="desktop-testimonial">
          <div className="desktop-gallery">
            {videoData.map((e) => (
              <div key={e.id}>
                <iframe
                  width="99%"
                  height="315"
                  src={e.link}
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
