import React from "react";
import { Container, Row } from "react-bootstrap";
import { theme } from "../../constants/theme";
import "./gallery.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ArrowRight from "../../assets/images/ArrowCircleRightBlue.svg";
import ArrowLeft from "../../assets/images/ArrowCircleLeftBlue.svg";
import { galleryDataDesktop, galleryDataMobile } from "./gallery-data";

export default function Gallery() {
  const arrowStyles = {
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 15px)",
    width: 50,
    height: 50,
    cursor: "pointer",
  };
  return (
    <div
      className="gallery-container"
      style={{ backgroundColor: theme.color.primaryLightest }}
    >
      <Container>
        <Row>
          <h1
            className="heading-text"
            style={{
              fontFamily: theme.fontFamily.bold,
              color: theme.color.headingText,
            }}
          >
            Gallery
          </h1>
        </Row>
        <div className="mobile-testimonial">
          <Carousel
            showStatus={false}
            showThumbs={false}
            swipeable={true}
            autoPlay
            infiniteLoop
            interval={4000}
            transitionTime={1000}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    ...arrowStyles,
                    left: "2.5%",
                    outline: "none",
                    border: "none",
                    background: "transparent",
                  }}
                >
                  <img src={ArrowLeft} />
                </button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    ...arrowStyles,
                    right: "2.5%",
                    outline: "none",
                    border: "none",
                    background: "transparent",
                  }}
                >
                  <img src={ArrowRight} />
                </button>
              )
            }
          >
            {galleryDataMobile.map((e) => (
              <div className="myGalleryCarousel galleryCarousel" key={e.id}>
                <img src={e.source} alt="gallery" />
              </div>
            ))}
          </Carousel>
        </div>
        <div className="desktop-testimonial">
          <Carousel
            showStatus={false}
            showThumbs={false}
            swipeable={true}
            autoPlay
            infiniteLoop
            interval={4000}
            transitionTime={1000}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    ...arrowStyles,
                    left: "10%",
                    outline: "none",
                    border: "none",
                    background: "transparent",
                  }}
                >
                  <img src={ArrowLeft} />
                </button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    ...arrowStyles,
                    right: "10%",
                    outline: "none",
                    border: "none",
                    background: "transparent",
                  }}
                >
                  <img src={ArrowRight} />
                </button>
              )
            }
          >
            {galleryDataDesktop.map((e) => (
              <div className="myGalleryCarousel galleryCarousel" key={e.id}>
                <img src={e.source} alt="gallery" />
              </div>
            ))}
          </Carousel>
        </div>
      </Container>
    </div>
  );
}
