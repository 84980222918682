import React from "react";
import { Container, Row } from "react-bootstrap";
import ServicesHeader from "../../../components/NavigationHeader/services-header";
import NotFromPatna from "../../../components/NotFromPatna/notFromPatna";
import { theme } from "../../../constants/theme";

export default function KneeArthroscopyView() {
  return (
    <div>
      <ServicesHeader title="Knee Arthroscopy" />
      <div className="service-list-container">
        <Container>
          <Row className="row-1">
            <div className="text-block">
              <h3
                className="heading-data"
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.headingText,
                  textAlign: "center",
                  marginBottom: 45,
                }}
              >
                About Knee Arthroscopy
              </h3>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Knee Arthroscopy can be performed asleep – general anaesthesia
                or awake with spinal anaesthesia. It is used to visualise the
                whole joint and treat cartilage or ligament problems. Patients
                may be discharged same day or might have to stay in the hospital
                a few days depending on treatment and physiotherapy needs.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 22,
                  fontSize: 24,
                }}
                className="listHeading"
              >
                ACL Reconstruction
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What is ACL?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                ACL means Anterior Cruciate Ligament. The ACL is among the group
                of ligaments inside the knee joint which connect the leg bone
                and the thigh bone. The ACL runs through the knee’s centre, and
                crosses the posterior cruciate ligament (PCL) diagonally.
                Together with the PCL, the ACL provides an important function –
                controlling the knee as it moves forwards or backwards. The most
                important function of the ACL is controlling the stability of
                the knee rotationally as you twist and turn such as in sports
                like cricket, running, football, rugby, basketball and so on. It
                is not uncommon for ACL injuries to occur concurrently with
                another torn ligament or tissue in the knee joint.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Is it necessary to have ACL Reconstruction?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                In general, a person is a candidate for an ACL reconstruction if
                their knee is not stable. If you feel your knee gives way or you
                fall down or have lost confidence then ACL Reconstruction
                surgery is helpful. ACL reconstruction offers the dual benefits
                of stabilizing the knee, while also preventing the knee
                cartilage from getting more injuries to it every time the knee
                gives way. Hence, it has a role in preventing future arthritis.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What happens during ACL Reconstruction Surgery?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Arthroscopy or Keyhole surgery is done to visualise and then
                reconstruct the ACL. For this reconstruction a graft tissue is
                needed which is taken from your same knee or in some cases like
                redo surgery from other knee using a small incision. Two most
                common grafts are hamstring and patella (kneecap) tendon. This
                is then weaved and arthroscopically fixed inside the knee using
                special implants. The surgery usually takes one and half hours
                to compete. This can be a day case or you might have to stay in
                hospital for a few days depending upon your rehab and
                physiotherapy which is supervised by trained physiotherapists.
                Usually patients are able to walk on the same day.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What is the cost of ACL Reconstruction Surgery?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Dr Das utilises US or European imported ACL Reconstruction
                implants with an excellent published track record. He has best
                surgical outcome with a very high patient satisfaction rate.
                This is provided at an affordable package cost which includes
                surgery, hospital stay, implants and physiotherapy. Please feel
                free to contact us to know about the ACL package.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 22,
                  fontSize: 24,
                }}
                className="listHeading"
              >
                PCL Reconstruction
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What is PCL?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                PCL means Posterior Cruciate Ligament. The PCL is among the
                group of ligaments inside the knee joint which connect the leg
                bone and the thigh bone. The PCL runs through the knee’s centre,
                and crosses the Anterior cruciate ligament (ACL) diagonally.
                Together with the ACL, the PCL provides an important function –
                controlling the knee as it moves forwards or backwards. It is
                not uncommon for PCL injuries to occur concurrently with another
                torn ligament or tissue in the knee joint.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Is it necessary to have PCL Reconstruction?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                In general, a person is a candidate for PCL reconstruction if
                their knee is not stable. If you feel your knee gives way or you
                fall down or have lost confidence then PCL Reconstruction
                surgery is helpful.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What happens during PCL Reconstruction Surgery?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Arthroscopy or Keyhole surgery is done to visualise and then
                reconstruct the PCL. For this reconstruction a graft tissue is
                needed which is taken from your same knee or in some cases like
                redo surgery from other knee using a small incision. Two most
                common grafts are hamstring and patella (knee-cap) tendon. This
                is then weaved and arthroscopically fixed inside the knee using
                special implants. The surgery usually takes one and half hours
                to complete. This can be a day case or you might have to stay in
                hospital for a few days depending upon your rehab and
                physiotherapy which is supervised by trained physiotherapists.
                Usually patients are able to walk on the same day.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What is the cost of PCL Reconstruction Surgery?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Dr Das utilises US or European imported implants with an
                excellent published track record. He has best surgical outcome
                with a very high patient satisfaction rate. This is provided at
                an affordable package cost which includes surgery, hospital
                stay, implants and physiotherapy. Please feel free to contact us
                for to know about the PCL Reconstruction Package.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 22,
                  fontSize: 24,
                }}
                className="listHeading"
              >
                Meniscus Repair and Partial Meniscectomy
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What is Meniscus?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Meniscus is a C Shaped semilunar cartilage which act as shock
                absorbers in the knee joint. They protect the joint from wear
                and tear. There are two menisci in each knee. A meniscus tear
                can be painful and debilitating. Unfortunately, it's quite
                common. In some cases, a piece of the shredded cartilage breaks
                loose and catches in the knee joint, causing it to lock up.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Is it necessary to have surgery for torn meniscus?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Meniscus tears can be painful and impact on your daily life
                activities. Treatment for meniscal tears depends on the size and
                location of the tear. Other factors which influence treatment
                include age, activity level and related injuries. Arthroscopic
                surgery is a good solution for meniscus related problems.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What happens during Torn Meniscus Surgery?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Arthroscopy or Keyhole surgery is done to visualise and the knee
                joint and the torn meniscus. Dr Das makes all possible attempts
                to repair the meniscus whenever possible especially in children
                and young adults, due to its role in preventing arthritis. If
                the tear is longstanding or irreparable, then meniscus is
                trimmed. Even at this stage, Dr Das tries to preserve as much of
                the meniscus as possible. The surgery usually takes less than an
                hour to complete. This can be a day case or you might have to
                stay in hospital for a few days depending upon your rehab and
                physiotherapy which is supervised by trained physiotherapists.
                Patients are able to walk on the same day.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What is the cost of Torn Meniscus Surgery?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Dr Das utilises US or European imported sutures and implants
                with an excellent published track record. He has best surgical
                outcome with a very high patient satisfaction rate. This is
                provided at an affordable package cost which includes surgery,
                hospital stay, implants and physiotherapy. Please feel free to
                contact us for to know about the Meniscus surgery Package.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 22,
                  fontSize: 24,
                }}
                className="listHeading"
              >
                Articular Cartilage Surgery
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Articular cartilage is the smooth shock absorbing lining of the
                bones of the knee joint. In acute phase of articular damage,
                treatment consists of ice, elevation and resting the joint.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Is it necessary to have surgery for articular cartilage damage?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                After the injury, if there is a large attached piece of bone,
                then it is worthwhile reattaching and fixing this. This may not
                be possible in longstanding cases of articular damage. The
                options available are:
                <ul
                  style={{
                    marginTop: 8,
                  }}
                >
                  <li
                    style={{
                      fontFamily: theme.fontFamily.light,
                      color: theme.color.lightText,
                      marginBottom: 10,
                      textAlign: "justify",
                    }}
                    className="listItem"
                  >
                    <span
                      style={{
                        fontFamily: theme.fontFamily.regular,
                        color: theme.color.lightText,
                        textAlign: "justify",
                      }}
                    >
                      Hyaluronic Acid Injection:
                    </span>{" "}
                    The principles behind Hyaluronic acid Injections are to
                    decrease pain and improve function in patients with joint
                    pain. They replace some of the normal ingredients found in
                    synovial fluid (hyaluronans) improving the lubricating
                    ability.
                  </li>
                  <li
                    style={{
                      fontFamily: theme.fontFamily.light,
                      color: theme.color.lightText,
                      marginBottom: 10,
                      textAlign: "justify",
                    }}
                    className="listItem"
                  >
                    <span
                      style={{
                        fontFamily: theme.fontFamily.regular,
                        color: theme.color.lightText,
                        textAlign: "justify",
                      }}
                    >
                      Debridement:
                    </span>{" "}
                    This would include the removal of any loose flaps of
                    articular cartilage that may be present. This is usually
                    done arthroscopically. The size, depth and location of the
                    lesion are recorded. If the lesion is small and less than 1
                    cm in diameter, this may be all that is required. If the
                    overlying articular cartilage flap is loose in the joint,
                    then this is removed. Similarly, if the piece that has been
                    knocked off is essentially all cartilage, this should be
                    removed, as reattaching it will serve no function as it
                    cannot heal.
                  </li>
                  <li
                    style={{
                      fontFamily: theme.fontFamily.light,
                      color: theme.color.lightText,
                      marginBottom: 10,
                      textAlign: "justify",
                    }}
                    className="listItem"
                  >
                    <span
                      style={{
                        fontFamily: theme.fontFamily.regular,
                        color: theme.color.lightText,
                        textAlign: "justify",
                      }}
                    >
                      Microfracture:
                    </span>{" "}
                    Full-thickness articular cartilage defect does not heal on
                    its own to form normal hyaline articular cartilage in
                    humans. The aim of microfracture is to make holes in the
                    exposed bone using a bone awl. The hole will penetrate the
                    subchondral region and cause bleeding into the defect. The
                    blood will then go on to form a clot and trap fibrous
                    tissue-producing cells in the clot. These cells then can
                    produce fibrous tissue (scar tissue) to fill or partially
                    fill the defect. The filled defect is not normal articular
                    cartilage. This is a fibrocartilage, which lines the defect.
                    This allows a great extent of painfree knee function which
                    would otherwise not be possible.
                  </li>
                  <li
                    style={{
                      fontFamily: theme.fontFamily.light,
                      color: theme.color.lightText,
                      marginBottom: 10,
                      textAlign: "justify",
                    }}
                    className="listItem"
                  >
                    <span
                      style={{
                        fontFamily: theme.fontFamily.regular,
                        color: theme.color.lightText,
                        textAlign: "justify",
                      }}
                    >
                      Mosaicplasty:
                    </span>{" "}
                    This technique involves taking a plugs of bone and overlying
                    cartilage from part of the knee which is non weight-bearing
                    and implanting the plug(s) into the cartilage defect. This
                    does have some limitations and is going out of favour.
                  </li>
                  <li
                    style={{
                      fontFamily: theme.fontFamily.light,
                      color: theme.color.lightText,
                      marginBottom: 10,
                      textAlign: "justify",
                    }}
                    className="listItem"
                  >
                    <span
                      style={{
                        fontFamily: theme.fontFamily.regular,
                        color: theme.color.lightText,
                        textAlign: "justify",
                      }}
                    >
                      Articular Cartilage Transplantation:
                    </span>{" "}
                    This Surgery is state of the art technique performed by Dr
                    Das. It involves taking a small sample of healthy cartilage
                    from the knee arthroscopically and sending it to a lab to
                    culture the cartilage cells. The cells are then returned
                    back and implanted into the knee defect at a second
                    operation. This is followed by a specific well designed
                    rehab programmed to allow the cartilage cell to heal.
                  </li>
                </ul>
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What is the cost of Articular Cartilage Surgery?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Dr Das utilises US or European imported sutures and implants
                with an excellent published track record. He has best surgical
                outcome with a very high patient satisfaction rate. The surgical
                cost is tailormade depending upon the clinical needs and the
                appropriate surgery required. This is provided at an affordable
                package cost which includes surgery, hospital stay, implants and
                physiotherapy. Please feel free to contact us for to know about
                the Articular Cartilage Surgery Package.
              </p>
            </div>
            <NotFromPatna />
          </Row>
        </Container>
      </div>
    </div>
  );
}
