import React from "react";
import About from "../../components/About/About";
import Details from "../../components/Details/Details";
import Gallery from "../../components/Gallery/Gallery";
import ImageCarousel from "../../components/ImageCarousel/ImageCarousel";
import Services from "../../components/Services/Services";
import Testimonials from "../../components/Testimonials/Testimonials";

export default function HomeView() {
  return (
    <>
      <ImageCarousel />
      <About />
      <Details />
      <Services />
      <Gallery />
      <Testimonials />
    </>
  );
}
