import React from "react";
import { Container, Row } from "react-bootstrap";
import ServicesHeader from "../../../components/NavigationHeader/services-header";
import NotFromPatna from "../../../components/NotFromPatna/notFromPatna";
import { theme } from "../../../constants/theme";

export default function ElbowArthroscopyView() {
  return (
    <div>
      <ServicesHeader title="Elbow Arthroscopy" />
      <div className="service-list-container">
        <Container>
          <Row className="row-1">
            <div className="text-block">
              <h3
                className="heading-data"
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.headingText,
                  textAlign: "center",
                  marginBottom: 45,
                }}
              >
                About Elbow Arthroscopy
              </h3>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Elbow Arthroscopy is usually performed asleep – general
                anaesthesia or awake with nerve block- numbing the arm. It is
                used to visualise the whole joint and treat stiffness or
                cartilage or ligament problems. Patients may be discharged same
                day or might have to stay in the hospital a few days depending
                on treatment and physiotherapy needs.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Elbow stiffness
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Elbow stiffness can occur as a result of injury or
                immobilisation. The joint capsule becomes tight in elbow
                stiffness. In this condition elbow arthroscopy can be performed
                and the whole joint is visualised. Then the tight capsule is cut
                using special pencil like instrument called radiofrequency wand.
                The surgery usually takes about an hour to compete. This can be
                a day case or you might have to stay in hospital for a day
                depending upon your rehab and physiotherapy which is supervised
                by trained physiotherapists.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Loose Body Removal
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Elbow locking can happen as a result of loose body in the joint.
                These loose bodies are free floating in the joint and cause
                locking off and on which are painful. In this condition elbow
                arthroscopy can be performed and the whole joint is visualised.
                The loose body can be removed using specialised equipments. The
                surgery usually takes about an hour to compete. This can be a
                day case or you might have to stay in hospital for a day
                depending upon your rehab and physiotherapy which is supervised
                by trained physiotherapists.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Painful elbow in Throwing Athlete
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                In a throwing athlete like Javelin throwers, bowlers and
                baseball pitchers elbow, the elbow becomes painful. This is due
                to overload of elbow joint. This can be assessed and treated
                using arthroscopy. The surgery usually takes about an hour to
                compete. This can be a day case or you might have to stay in
                hospital for a day depending upon your rehab and physiotherapy
                which is supervised by trained physiotherapists.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Is it necessary to have Elbow arthroscopy?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                For elbow conditions, usually a course of supervised physical
                therapy is recommended first. However, if the joint remains
                stiff, painful or locks, then it requires surgery.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What Happens during Elbow Arthroscopy?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Elbow Arthroscopy is performed asleep – general anaesthesia. It
                is used to visualise the whole joint. In elbow stiffness the
                capsule becomes tight. The tight capsule is cut using special
                pencil like instrument called radiofrequency wand. Elbow locking
                is usually caused by loose body which is arthroscopically
                removed. Bony overgrowth around the elbow called bone spur can
                be painful. This can be removed arthroscopically. The surgery
                usually takes about an hour. Patients may be discharged same day
                or might have to stay in the hospital a few days depending on
                treatment and physiotherapy needs.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What is the cost of Elbow Arthroscopy?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Dr Das utilises US or European imported equipments. He has best
                surgical outcome with a very high patient satisfaction rate.
                This is provided at an affordable package cost which includes
                surgery, hospital stay and physiotherapy. Please feel free to
                contact us to know about the elbow arthroscopy package.
              </p>
            </div>
            <NotFromPatna />
          </Row>
        </Container>
      </div>
    </div>
  );
}
