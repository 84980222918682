import React from "react";
import { Container, Row } from "react-bootstrap";
import ServicesHeader from "../../../components/NavigationHeader/services-header";
import NotFromPatna from "../../../components/NotFromPatna/notFromPatna";
import { theme } from "../../../constants/theme";

export default function TraumaSurgeryView() {
  return (
    <div>
      <ServicesHeader title="Trauma Surgery" />
      <div className="service-list-container">
        <Container>
          <Row className="row-1">
            <div className="text-block">
              <h3
                className="heading-data"
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.headingText,
                  textAlign: "center",
                  marginBottom: 45,
                }}
              >
                About Trauma Surgery
              </h3>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Trauma is the term used for injuries sustained as a result of
                blunt force or penetrating injuries. Dr Das had extensive
                training in the management of both upper and lower limb
                fractures.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Periprosthetic Joint Fracture Management
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Periprosthetic fractures are fractures around pre-existing
                implants like femur fracture where previous total knee or total
                hip replacement was done. Similarly, humerus fractures around
                pre-existing implants where previous total shoulder or total
                elbow replacement are also periprosthetic fractures. These are
                complex fractures which requires extensive pre-surgical
                planning.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Complex and Periarticular Fracture Management
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Fractures around and involving a joint are called periarticular
                fractures. These are also complex fractures which requires
                extensive pre-surgical planning.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What happens during Trauma Surgery?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                This depends on the nature of injury and fracture. First step is
                to stop the blood loss. If there is vascular injury then
                multidisciplinary approach is required with involvement of
                General surgeons, Plastic and Vascular surgeons as well. The
                next step is to stabilise the fracture which can be done using a
                variety of implants depending on the fracture pattern and the
                skin and muscle envelope around the bone. Peri-prosthetic
                fractures are highly complex and management ranges from fixation
                of fracture using implants to re-doing the joint replacement
                called Revision Joint Replacement.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What is the cost of Trauma Surgery?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Dr Das has best surgical outcome with a very high patient
                satisfaction rate. This is provided at an affordable package
                cost which includes surgery, hospital stay and physiotherapy.
                Please feel free to contact us to know about the trauma surgery
                package.
              </p>
            </div>
            <NotFromPatna />
          </Row>
        </Container>
      </div>
    </div>
  );
}
