import React from "react";
import { Route, Routes } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import AboutView from "../views/AboutView/AboutView";
import BookAppointmentView from "../views/BookAppointmentView/BookAppointmentView";
import ContactView from "../views/ContactView/ContactView";
import GalleryView from "../views/GalleryView/GalleryView";
import HomeView from "../views/HomeView/HomeView";
import NotFoundView from "../views/NotFoundView/NotFoundView";
import OnlineConsultationView from "../views/OnlineConsultationView/OnlineConsultationView";
import ACLSurgeryView from "../views/ServicesView/services/aclSurgery";
import AnkleArthroscopyView from "../views/ServicesView/services/ankleArthrocopy";
import ElbowArthroscopyView from "../views/ServicesView/services/elbowArthroscopy";
import ElbowReplacementView from "../views/ServicesView/services/elbowReplacement";
import FingerJointReplacementView from "../views/ServicesView/services/fingerJointReplacement";
import HipReplacementView from "../views/ServicesView/services/hipReplacement";
import KneeArthroscopyView from "../views/ServicesView/services/kneeArthroscopy";
import KneeReplacementView from "../views/ServicesView/services/kneeReplacement";
import ShoulderArthroscopyView from "../views/ServicesView/services/shoulderArthroscopy";
import ShoulderDislocationView from "../views/ServicesView/services/shoulderDislocation";
import ShoulderReplacementView from "../views/ServicesView/services/shoulderReplacement";
import TraumaSurgeryView from "../views/ServicesView/services/traumaSurgery";
import ServicesView from "../views/ServicesView/ServicesView";
import SportsMedicineView from "../views/SportsMedicineView/SportsMedicineView";
import VideosView from "../views/VideosView/VideosView";

export default function Layout() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="*" element={<NotFoundView />} />
        <Route path="/" element={<HomeView />} />
        <Route path="about" element={<AboutView />} />
        <Route path="our-services" element={<ServicesView />} />
        <Route
          path="our-services/shoulder-replacement"
          element={<ShoulderReplacementView />}
        />
        <Route
          path="our-services/knee-replacement"
          element={<KneeReplacementView />}
        />
        <Route
          path="our-services/elbow-replacement"
          element={<ElbowReplacementView />}
        />
        <Route
          path="our-services/hip-replacement"
          element={<HipReplacementView />}
        />
        <Route
          path="our-services/finger-joint-replacement"
          element={<FingerJointReplacementView />}
        />
        <Route
          path="our-services/shoulder-arthroscopy"
          element={<ShoulderArthroscopyView />}
        />
        <Route
          path="our-services/knee-arthroscopy"
          element={<KneeArthroscopyView />}
        />
        <Route
          path="our-services/elbow-arthroscopy"
          element={<ElbowArthroscopyView />}
        />
        <Route
          path="our-services/ankle-arthroscopy"
          element={<AnkleArthroscopyView />}
        />
        <Route
          path="our-services/trauma-surgery"
          element={<TraumaSurgeryView />}
        />
        <Route path="our-services/acl-surgery" element={<ACLSurgeryView />} />
        <Route
          path="our-services/shoulder-dislocation"
          element={<ShoulderDislocationView />}
        />
        <Route path="sports-medicine" element={<SportsMedicineView />} />
        <Route path="gallery" element={<GalleryView />} />
        <Route path="videos" element={<VideosView />} />
        <Route path="contact" element={<ContactView />} />
        <Route
          path="online-consultation"
          element={<OnlineConsultationView />}
        />
        <Route path="book-appointment" element={<BookAppointmentView />} />
      </Routes>
      <Footer />
    </>
  );
}
