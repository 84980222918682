import About from "../../assets/images/userAvatar.png";

export const testimonialData = [
  {
    id: 0,
    name: "Zelda Matthews",
    location: "Skipton, England",
    source: About,
    testimonialGiven:
      "Great Assessment for my knee pain. The doctor actually listened to me and was compassionate.",
  },
  {
    id: 5,
    name: "Sanjeev Singh",
    location: "Chapra, Bihar",
    source: About,
    testimonialGiven:
      "I had wound over my ankle with bone infection and pus. This was not settling for a year and local doctors had advised to amputate my leg. Dr Das performed surgery which has helped improve the wound. With his personal care and wound dressing the infection has settled and wound is healing. I am so happy that my leg is saved. Thank you so much Dr Das.",
  },
  {
    id: 3,
    name: "Mehrunisaa",
    location: "Patna, Bihar",
    source: About,
    testimonialGiven:
      "I was having severe shoulder pain for last one year. Dr Das treated my problem with shoulder injection and physio. This has helped me a lot in relieving pain and making my shoulder better.",
  },
  {
    id: 2,
    name: "Jyoti",
    location: "Siwan, Bihar",
    source: About,
    testimonialGiven:
      "I had been having trouble with my knee for last 2 years. Dr Das diagnosed that I had ACL tear and treated with arthroscopic surgery. I am pleased and grateful for surgery and am back to normal activities.",
  },
  {
    id: 1,
    name: "Pt Sony Kumari",
    location: "Motihari, Bihar",
    source: About,
    testimonialGiven:
      "I had patella dislocation surgery.  Dr Das treated me and after the rehab I am back to all my activities including sports.",
  },
  {
    id: 4,
    name: "Kuljeet Kaur",
    location: "Chapra, Bihar",
    source: About,
    testimonialGiven:
      "I had been having severe elbow pain for 2 years. I had previous multiple injections with no benefit. Dr Das diagnosed my tennis elbow and performed PRP injection. This has helped me tremendously. Finally there is freedom from pain. Thank you",
  },

  // {
  //   id: 6,
  //   name: "Joe Crawford",
  //   location: "3 Denison Road",
  //   source: About,
  //   testimonialGiven:
  //     "lorem ipsum dolor sit lorem ipsum dolor sit lorem ipsum dolor sit lorem ipsum dolor sit",
  // },
  // {
  //   id: 7,
  //   name: "Isobel Rowley",
  //   location: "27 Myrtle Street",
  //   source: About,
  //   testimonialGiven:
  //     "lorem ipsum dolor sit lorem ipsum dolor sit lorem ipsum dolor sit lorem ipsum dolor sit",
  // },
  // {
  //   id: 8,
  //   name: "Andrey Hose",
  //   location: "Stockton, CA 95202",
  //   source: About,
  //   testimonialGiven:
  //     "lorem ipsum dolor sit lorem ipsum dolor sit lorem ipsum dolor sit lorem ipsum dolor sit",
  // },
];
