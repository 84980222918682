import React from "react";
import { Container, Row } from "react-bootstrap";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ArrowLeft from "../../assets/images/ArrowCircleLeftBlue.svg";
import ArrowRight from "../../assets/images/ArrowCircleRightBlue.svg";
import { theme } from "../../constants/theme";
import { testimonialData } from "./testimonials-data";
import "./testimonials.css";
export default function Testimonials() {
  const arrowStyles = {
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 15px)",
    width: 50,
    height: 50,
    cursor: "pointer",
  };

  return (
    <div className="testimonial-container">
      <Container>
        <Row>
          <h1
            className="heading-text"
            style={{
              fontFamily: theme.fontFamily.bold,
              color: theme.color.headingText,
            }}
          >
            Testimonials
          </h1>
        </Row>

        <div className="mobile-testimonial">
          <Carousel
            showStatus={false}
            showThumbs={false}
            swipeable={true}
            showIndicators={false}
            autoPlay
            infiniteLoop
            interval={4000}
            transitionTime={1000}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    ...arrowStyles,
                    left: "4%",
                    outline: "none",
                    border: "none",
                    background: "transparent",
                    marginTop: 24,
                  }}
                >
                  <img src={ArrowLeft} />
                </button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    ...arrowStyles,
                    right: "4%",
                    outline: "none",
                    border: "none",
                    background: "transparent",
                    marginTop: 24,
                  }}
                >
                  <img src={ArrowRight} />
                </button>
              )
            }
          >
            {testimonialData.map((e) => (
              <div>
                <img src={e.source} className="testimonial-img" />
                <div className="myCarousel">
                  <h3
                    className="user-name"
                    style={{
                      fontFamily: theme.fontFamily.bold,
                      color: theme.color.darkText,
                    }}
                  >
                    {e.name}
                  </h3>
                  <h4
                    className="user-location"
                    style={{
                      fontFamily: theme.fontFamily.light,
                      color: theme.color.darkText,
                    }}
                  >
                    {e.location}
                  </h4>
                  <p
                    className="user-testimonial"
                    style={{
                      fontFamily: theme.fontFamily.light,
                      color: theme.color.lightText,
                    }}
                  >
                    {e.testimonialGiven}
                  </p>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
        <div className="desktop-testimonial">
          <Carousel
            showStatus={false}
            showThumbs={false}
            showIndicators={true}
            swipeable={true}
            autoPlay
            infiniteLoop
            interval={4000}
            transitionTime={1000}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    ...arrowStyles,
                    left: "2%",
                    outline: "none",
                    border: "none",
                    marginTop: 32,
                    background: "transparent",
                  }}
                >
                  <img src={ArrowLeft} />
                </button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    ...arrowStyles,
                    right: "2%",
                    outline: "none",
                    border: "none",
                    background: "transparent",
                    marginTop: 32,
                  }}
                >
                  <img src={ArrowRight} />
                </button>
              )
            }
          >
            {testimonialData.map((e) => (
              <div style={{ marginBottom: 36 }}>
                <img src={e.source} className="testimonial-img" />
                <div className="myCarousel">
                  <h3
                    className="user-name"
                    style={{
                      fontFamily: theme.fontFamily.bold,
                      color: theme.color.darkText,
                    }}
                  >
                    {e.name}
                  </h3>
                  <h4
                    className="user-location"
                    style={{
                      fontFamily: theme.fontFamily.light,
                      color: theme.color.darkText,
                    }}
                  >
                    {e.location}
                  </h4>
                  <p
                    className="user-testimonial"
                    style={{
                      fontFamily: theme.fontFamily.light,
                      color: theme.color.lightText,
                    }}
                  >
                    {e.testimonialGiven}
                  </p>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </Container>
    </div>
  );
}
