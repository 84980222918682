import Image from "../../assets/images/img1.png";
import Image2 from "../../assets/images/img2.png";
import Image3 from "../../assets/images/img3.png";
import Image4 from "../../assets/images/img4.png";
import Image5 from "../../assets/images/img5.png";
import Image6 from "../../assets/images/img6.png";
import Image7 from "../../assets/images/img7.png";
import ImageMobile from "../../assets/images/img1mobile.png";
import Image2Mobile from "../../assets/images/img2mobile.png";
import Image3Mobile from "../../assets/images/img3mobile.png";
import Image4Mobile from "../../assets/images/img4mobile.png";
import Image5Mobile from "../../assets/images/img5mobile.png";
import Image6Mobile from "../../assets/images/img6mobile.png";
import Image7Mobile from "../../assets/images/img7mobile.png";

export const galleryDataDesktop = [
  {
    id: 0,
    source: Image,
  },
  {
    id: 1,
    source: Image2,
  },
  {
    id: 2,
    source: Image3,
  },
  {
    id: 3,
    source: Image4,
  },
  {
    id: 4,
    source: Image5,
  },
  {
    id: 5,
    source: Image6,
  },
  {
    id: 6,
    source: Image7,
  },
];

export const galleryDataMobile = [
  {
    id: 0,
    source: ImageMobile,
  },
  {
    id: 1,
    source: Image2Mobile,
  },
  {
    id: 2,
    source: Image3Mobile,
  },
  {
    id: 3,
    source: Image4Mobile,
  },
  {
    id: 4,
    source: Image5Mobile,
  },
  {
    id: 5,
    source: Image6Mobile,
  },
  {
    id: 6,
    source: Image7Mobile,
  },
];
