import React from "react";
import { Container, Row } from "react-bootstrap";
import ServicesHeader from "../../../components/NavigationHeader/services-header";
import NotFromPatna from "../../../components/NotFromPatna/notFromPatna";
import { theme } from "../../../constants/theme";

export default function ShoulderDislocationView() {
  return (
    <div>
      <ServicesHeader title="Shoulder Dislocation" />
      <div className="service-list-container">
        <Container>
          <Row className="row-1">
            <div className="text-block">
              <h3
                className="heading-data"
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.headingText,
                  textAlign: "center",
                  marginBottom: 45,
                }}
              >
                About Shoulder Dislocation
              </h3>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Bankart Repair for Shoulder Dislocation
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Shoulder is a ball and socket joint which is large range of
                movement. This makes the joint inherently unstable. One of the
                most important factors that stabilise the shoulder joint include
                Capsulolabral complex. This is torn during shoulder dislocation
                causing Bankart lesion. Every time shoulder dislocates it causes
                damage to humeral hear which is Hill-Sach lesion. It can also
                cause damage the glenoid/ shoulder socket. There are techniques
                to treat a recurrent shoulder dislocation. If there isn’t much
                damage to the shoulder head and glenoid socket, then All
                Arthroscopic repair of Bankart lesion is performed by Dr Das
                which is highly successful. This is followed by his supervised
                rehab programme.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Additional Arthroscopic Procedures for Shoulder Dislocation
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                In certain cases of shoulder dislocation there is fracture of
                the shoulder socket glenoid rim. This is called a Bony Bankart
                Lesion. Dr Das uses All Arthroscopic technique for Bony Bankart
                Repair. In a few cases, especially professional athletes, there
                is a high risk of redislocation after arthroscopic Bankart
                Repair. This is probably due to the fact that small defect in
                the ball of the shoulder joint can still engage with the socket
                rim causing dislocation. In these cases Dr Das uses additional
                arthroscopic technique called ‘REMPLISSAGE’. This is a French
                word meaning ‘To Fill In’. The defect is filled using a suture
                anchor and using part of the rotator cuff tendon called
                Infraspinatus.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Latarjet Technique
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                If there is severe damage to shoulder head and glenoid socket
                then an open Laterjet technique is preferred. This is French
                Technique where a part of the bone with attached tendon around
                the shoulder (Coracoid process) is transferred to the deficient
                glenoid socket. Apart from the reconstructing the glenoid
                socket, the inferior ligament is also reinforced. Thus a sling
                or seat belt effect is created preventing shoulder from
                dislocation.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What happens during Arthroscopic surgery for Shoulder
                Dislocation?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Arthroscopy or Keyhole surgery is done to visualise the shoulder
                joint. The detached capsule-labral complex is identified and
                fixed to the glenoid rim using suture anchors. If there is a
                Bony Bankart lesion it is fixed using suture anchor as well. If
                there is a further need to address the Hill Sach lesion on the
                humeral head a Remplissage procedure is performed. Remplissage
                is a French word which means ‘to fill in’ . This is done using a
                suture anchor and is performed at the same sitting. All these
                high end procedures are performed arthroscopically.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What is the cost of Arthroscopic Procedures for Shoulder
                Dislocation?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Dr Das utilises US or European imported shoulder arthroscopy
                implants with an excellent published track record. He has best
                surgical outcome with a very high patient satisfaction rate.
                This is provided at an affordable package cost which includes
                surgery, hospital stay, implants and physiotherapy. Please feel
                free to contact us for to know about the package for shoulder
                dislocation surgery.
              </p>
            </div>
            <NotFromPatna />
          </Row>
        </Container>
      </div>
    </div>
  );
}
