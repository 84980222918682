import React from "react";
import { theme } from "../../constants/theme";

export default function NotFromPatna() {
  return (
    <div className="not-from-patna-block">
      <p
        style={{
          fontFamily: theme.fontFamily.bold,
          color: theme.color.offWhite,
          textAlign: "center",
          fontSize: 20,
        }}
      >
        Not from Patna? Do not Worry
      </p>
      <p
        style={{
          fontFamily: theme.fontFamily.light,
          color: theme.color.white,
          textAlign: "center",
        }}
      >
        If you are not from Patna we can provide video consultation and organise
        surgery which is convenient and can be planned by you. Dr Das is one of
        the best Orthopaedic surgeon not only in Patna but India as well. His
        vision is to help patients from all over the country and internationally
        to get the best Orthopaedic treatment. Contact for video consultation.
      </p>
    </div>
  );
}
