import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { detailsData } from "./detail-data";
import "./details.css";
import { theme } from "../../constants/theme";

export default function Details() {
  return (
    <div
      className="details-container"
      style={{ backgroundColor: theme.color.primaryLightest }}
    >
      <Container>
        <Row sm="1" md="2" lg="2" xl={4}>
          {detailsData.map((e) => (
            <Col>
              <div key={e.id} className="detail-items">
                <img
                  src={e.source}
                  alt="image"
                  style={{ width: 120, height: 120 }}
                />
                <p
                  className="details-text"
                  style={{
                    fontFamily: theme.fontFamily.regular,
                    color: theme.color.darkText,
                  }}
                >
                  {e.title}
                </p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}
