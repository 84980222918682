import React from "react";
import { Container, Row } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import NavigationHeader from "../../components/NavigationHeader/navigation-header";
import { theme } from "../../constants/theme";

export default function SportsMedicineView() {
  return (
    <div>
      <NavigationHeader title="Sports Medicine" />
      <div className="service-list-container">
        <Container>
          <Row className="row-1">
            <div className="text-block">
              <h3
                className="heading-data"
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.headingText,
                  textAlign: "center",
                  marginBottom: 45,
                }}
              >
                About Sports Medicine
              </h3>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Any injuries sustained by playing sports but it can also happen
                during normal daily activities. These injuries are usually
                located around the joints. They are usually related to injury of
                ligaments, capsules, muscle or cartilage.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                ACL Tear
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                ACL means Anterior Cruciate Ligament. The ACL is among the group
                of ligaments inside the knee joint which connect the leg bone
                and the thigh bone. The ACL runs through the knee’s centre, and
                crosses the posterior cruciate ligament (PCL) diagonally.
                Together with the PCL, the ACL provides an important function –
                controlling the knee as it moves forwards or backwards. The most
                important function of the ACL is controlling the stability of
                the knee rotationally as you twist and turn such as in sports
                like cricket, running, football, rugby, basketball and so on. It
                is not uncommon for ACL injuries to occur concurrently with
                another torn ligament or tissue in the knee joint.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Meniscus Tear
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Meniscus is a C Shaped semilunar cartilage which act as shock
                absorbers in the knee joint. They protect the joint from wear
                and tear. There are two menisci in each knee. A meniscus tear
                can be painful and debilitating. Unfortunately, it's quite
                common. In some cases, a piece of the shredded cartilage breaks
                loose and catches in the knee joint, causing it to lock up.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Multi-Ligamentous Tear
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                These are serious knee injuries often associated with knee
                dislocation. This requires urgent treatment. More than one
                ligament viz. ACL, PCL, MCL, LCL along with other Meniscus can
                be torn.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Cartilage Injuries
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 30,
                }}
              >
                Cartilage injuries are painful and can restrict your sporting or
                daily activities. They need full assessment and treatment to
                prevent progression to full blown arthritis.
              </p>
              <Link
                to="/our-services/knee-arthroscopy"
                className="read-more-btn"
                style={{
                  borderColor: theme.color.primary,
                  color: theme.color.primary,
                  fontFamily: theme.fontFamily.bold,
                  borderWidth: 1,
                  borderStyle: "solid",
                }}
              >
                Read more
                <FaArrowRight size={12} className="icon-right" />
              </Link>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 42,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Shoulder Dislocation
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Shoulder is a ball and socket joint which is large range of
                movement. This makes the joint inherently unstable. One of the
                most important factors that stabilise the shoulder joint include
                Capsulolabral complex. This is torn during shoulder dislocation
                causing Bankart lesion. Every time shoulder dislocates it causes
                damage to humeral hear which is Hill-Sach lesion. It can also
                cause damage the glenoid/ shoulder socket. There are techniques
                to treat a recurrent shoulder dislocation. If there isn’t much
                damage to the shoulder head and glenoid socket, then All
                Arthroscopic repair of Bankart lesion is performed by Dr Das
                which is highly successful. This is followed by his supervised
                rehab programme.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Rotator Cuff Tear
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 30,
                }}
              >
                Rotator Cuff Tear happens more commonly in overhead athletes
                like bowlers and tennis players. However they are equally common
                in non-sporting adults.
              </p>
              <Link
                to="/our-services/shoulder-arthroscopy"
                className="read-more-btn"
                style={{
                  borderColor: theme.color.primary,
                  color: theme.color.primary,
                  fontFamily: theme.fontFamily.bold,
                  borderWidth: 1,
                  borderStyle: "solid",
                }}
              >
                Read more
                <FaArrowRight size={12} className="icon-right" />
              </Link>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 42,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Ankle Injury
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Ankle joint is made up of 3 bones - distal tibia, fibula and
                talus. These are bound together by ligaments. It is one of the
                commonnest joint to get injured during sporting activity. The
                lateral ligament ATFL is the most common ligament injury causing
                ankle sprain. Ankle has medial and lateral ligament complex,
                injuries of these leads to instability and pain.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Ligaments around the ankle
              </p>
              <ul>
                <li
                  style={{
                    fontFamily: theme.fontFamily.light,
                    color: theme.color.lightText,
                    marginBottom: 6,
                    textAlign: "justify",
                  }}
                  className="listItem"
                >
                  <span
                    style={{
                      fontFamily: theme.fontFamily.regular,
                      color: theme.color.lightText,
                      textAlign: "justify",
                    }}
                  >
                    Medial :
                  </span>
                  <ul>
                    <li
                      style={{
                        fontFamily: theme.fontFamily.light,
                        color: theme.color.lightText,
                        marginBottom: 10,
                        textAlign: "justify",
                      }}
                      className="listItem"
                    >
                      Deltoid Ligament
                    </li>
                  </ul>
                </li>
                <li
                  style={{
                    fontFamily: theme.fontFamily.light,
                    color: theme.color.lightText,
                    marginBottom: 6,
                    textAlign: "justify",
                  }}
                  className="listItem"
                >
                  <span
                    style={{
                      fontFamily: theme.fontFamily.regular,
                      color: theme.color.lightText,
                      textAlign: "justify",
                    }}
                  >
                    Lateral:
                  </span>
                  <ul>
                    <li
                      style={{
                        fontFamily: theme.fontFamily.light,
                        color: theme.color.lightText,
                        marginBottom: 10,
                        textAlign: "justify",
                      }}
                      className="listItem"
                    >
                      ATFL, PTFL, CFL
                    </li>
                  </ul>
                </li>
                <li
                  style={{
                    fontFamily: theme.fontFamily.light,
                    color: theme.color.lightText,
                    marginBottom: 6,
                    textAlign: "justify",
                  }}
                  className="listItem"
                >
                  <span
                    style={{
                      fontFamily: theme.fontFamily.regular,
                      color: theme.color.lightText,
                      textAlign: "justify",
                    }}
                  >
                    Syndesmosis :
                  </span>{" "}
                  This is the joint between distal tibia and fibula.
                </li>
              </ul>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                These injuries can be of varying severity :
                <ul>
                  <li
                    style={{
                      fontFamily: theme.fontFamily.light,
                      color: theme.color.lightText,
                      textAlign: "justify",
                    }}
                    className="listItem"
                  >
                    Grade 1 - Stretching of ligament
                  </li>
                  <li
                    style={{
                      fontFamily: theme.fontFamily.light,
                      color: theme.color.lightText,
                      textAlign: "justify",
                    }}
                    className="listItem"
                  >
                    Grade 2 - Partial tear
                  </li>
                  <li
                    style={{
                      fontFamily: theme.fontFamily.light,
                      color: theme.color.lightText,
                      textAlign: "justify",
                    }}
                    className="listItem"
                  >
                    Grade 3 - Complete tear
                  </li>
                </ul>
                Initially these injuries requires rest, ice, elevation and
                physiotherapy. However Grade 3 sprains requires surgical
                management.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Cartilage Injuries
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 30,
                }}
              >
                Cartilage injuries are painful and can restrict your sporting or
                daily activities. They need full assessment and treatment to
                prevent progression to full blown arthritis.
              </p>
              <Link
                to="/our-services/ankle-arthroscopy"
                className="read-more-btn"
                style={{
                  borderColor: theme.color.primary,
                  color: theme.color.primary,
                  fontFamily: theme.fontFamily.bold,
                  borderWidth: 1,
                  borderStyle: "solid",
                }}
              >
                Read more
                <FaArrowRight size={12} className="icon-right" />
              </Link>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 42,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Elbow Injury
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Elbow is a joint made up of distal humerus, proximal radius and
                ulna. These are bound together by ligaments. These ligaments are
                most commonly injured during elbow dislocation. The elbow
                ligaments are :
                <ul
                  style={{
                    marginTop: 12,
                  }}
                >
                  <li
                    style={{
                      fontFamily: theme.fontFamily.light,
                      color: theme.color.lightText,
                      marginBottom: 6,
                      textAlign: "justify",
                    }}
                    className="listItem"
                  >
                    <span
                      style={{
                        fontFamily: theme.fontFamily.regular,
                        color: theme.color.lightText,
                        textAlign: "justify",
                      }}
                    >
                      Lateral ligament complex :
                    </span>
                    <ul>
                      <li
                        style={{
                          fontFamily: theme.fontFamily.light,
                          color: theme.color.lightText,
                          marginBottom: 10,
                          textAlign: "justify",
                        }}
                        className="listItem"
                      >
                        LUCL, RCL, Annular ligament
                      </li>
                    </ul>
                  </li>
                  <li
                    style={{
                      fontFamily: theme.fontFamily.light,
                      color: theme.color.lightText,
                      marginBottom: 6,
                      textAlign: "justify",
                    }}
                    className="listItem"
                  >
                    <span
                      style={{
                        fontFamily: theme.fontFamily.regular,
                        color: theme.color.lightText,
                        textAlign: "justify",
                      }}
                    >
                      MCL Complex :
                    </span>
                    <ul>
                      <li
                        style={{
                          fontFamily: theme.fontFamily.light,
                          color: theme.color.lightText,
                          marginBottom: 10,
                          textAlign: "justify",
                        }}
                        className="listItem"
                      >
                        Anterior, Posterior and Transverse
                      </li>
                    </ul>
                  </li>
                </ul>
                <p
                  className="desc-text"
                  style={{
                    fontFamily: theme.fontFamily.light,
                    color: theme.color.lightText,
                    marginBottom: 12,
                  }}
                >
                  These injuries can be of varying severity :
                  <ul>
                    <li
                      style={{
                        fontFamily: theme.fontFamily.light,
                        color: theme.color.lightText,
                        textAlign: "justify",
                      }}
                      className="listItem"
                    >
                      Grade 1 - Stretching of ligament
                    </li>
                    <li
                      style={{
                        fontFamily: theme.fontFamily.light,
                        color: theme.color.lightText,
                        textAlign: "justify",
                      }}
                      className="listItem"
                    >
                      Grade 2 - Partial tear
                    </li>
                    <li
                      style={{
                        fontFamily: theme.fontFamily.light,
                        color: theme.color.lightText,
                        textAlign: "justify",
                      }}
                      className="listItem"
                    >
                      Grade 3 - Complete tear
                    </li>
                  </ul>
                  Elbow dislocation requires urgent reduction followed by rest,
                  ice, elevation and physiotherapy. However Grade 3 sprains
                  requires surgical management.
                </p>
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Painful elbow in Throwing Athlete
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 30,
                }}
              >
                In a throwing athlete like Javelin throwers, bowlers and
                baseball pitchers elbow, the elbow becomes painful. This is due
                to overload of elbow joint. This can be assessed and treated
                using arthroscopy. The surgery usually takes about an hour to
                compete. This can be a day case or you might have to stay in
                hospital for a day depending upon your rehab and physiotherapy
                which is supervised by trained physiotherapists.
              </p>
              <Link
                to="/our-services/elbow-arthroscopy"
                className="read-more-btn"
                style={{
                  borderColor: theme.color.primary,
                  color: theme.color.primary,
                  fontFamily: theme.fontFamily.bold,
                  borderWidth: 1,
                  borderStyle: "solid",
                }}
              >
                Read more
                <FaArrowRight size={12} className="icon-right" />
              </Link>
            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
}
