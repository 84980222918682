export const menuItems = [
  {
    id: 0,
    title: "Home",
    path: "/",
  },
  {
    id: 1,
    title: "About",
    path: "/about",
  },
  {
    id: 2,
    title: "Services",
    path: "/our-services",
  },
  {
    id: 3,
    title: "Sports Medicine",
    path: "/sports-medicine",
  },
  {
    id: 4,
    title: "Gallery",
    path: "/gallery",
  },
  {
    id: 5,
    title: "Videos",
    path: "/videos",
  },
  {
    id: 6,
    title: "Contact",
    path: "/contact",
  },
  {
    id: 7,
    title: "Online Consultation",
    path: "/online-consultation",
  },
];
