import React from "react";
import NavigationHeader from "../../components/NavigationHeader/navigation-header";

export default function OnlineConsultationView() {
  return (
    <div>
      <NavigationHeader title="Online Consultation" />
    </div>
  );
}
