import React from "react";
import Image from "../../assets/images/img1.png";
import Image2 from "../../assets/images/img2.png";
import Image3 from "../../assets/images/img3.png";
import Image4 from "../../assets/images/img4.png";
import Image5 from "../../assets/images/img5.png";
import Image6 from "../../assets/images/img6.png";
import Image7 from "../../assets/images/img7.png";
import ImageMobile from "../../assets/images/img1mobile.png";
import Image2Mobile from "../../assets/images/img2mobile.png";
import Image3Mobile from "../../assets/images/img3mobile.png";
import Image4Mobile from "../../assets/images/img4mobile.png";
import Image5Mobile from "../../assets/images/img5mobile.png";
import Image6Mobile from "../../assets/images/img6mobile.png";
import Image7Mobile from "../../assets/images/img7mobile.png";
import ArrowRight from "../../assets/images/ArrowCircleRight.svg";
import ArrowLeft from "../../assets/images/ArrowCircleLeft.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./image-carousel.css";

export default function ImageCarousel() {
  const arrowStyles = {
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 15px)",
    width: 50,
    height: 50,
    cursor: "pointer",
  };
  return (
    <>
      <div className="desktop-carousel">
        <Carousel
          showStatus={false}
          showThumbs={false}
          swipeable={true}
          autoPlay
          infiniteLoop
          interval={4000}
          transitionTime={1000}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                style={{
                  ...arrowStyles,
                  left: 40,
                  outline: "none",
                  border: "none",
                  background: "transparent",
                }}
              >
                <img src={ArrowLeft} />
              </button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                style={{
                  ...arrowStyles,
                  right: 40,
                  outline: "none",
                  border: "none",
                  background: "transparent",
                }}
              >
                <img src={ArrowRight} />
              </button>
            )
          }
        >
          <div>
            <img src={Image5} />
          </div>
          <div>
            <img src={Image} />
          </div>
          <div>
            <img src={Image2} />
          </div>
          <div>
            <img src={Image4} />
          </div>
          <div>
            <img src={Image3} />
          </div>
          <div>
            <img src={Image6} />
          </div>
          <div>
            <img src={Image7} />
          </div>
        </Carousel>
      </div>
      <div className="mobile-carousel">
        <Carousel
          showStatus={false}
          showThumbs={false}
          swipeable={true}
          autoPlay
          infiniteLoop
          interval={4000}
          transitionTime={1000}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                style={{
                  ...arrowStyles,
                  left: 20,
                  outline: "none",
                  border: "none",
                  background: "transparent",
                }}
              >
                <img src={ArrowLeft} />
              </button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                style={{
                  ...arrowStyles,
                  right: 20,
                  outline: "none",
                  border: "none",
                  background: "transparent",
                }}
              >
                <img src={ArrowRight} />
              </button>
            )
          }
        >
          <div>
            <img src={Image5Mobile} />
          </div>
          <div>
            <img src={ImageMobile} />
          </div>
          <div>
            <img src={Image2Mobile} />
          </div>
          <div>
            <img src={Image4Mobile} />
          </div>
          <div>
            <img src={Image3Mobile} />
          </div>
          <div>
            <img src={Image6Mobile} />
          </div>
          <div>
            <img src={Image7Mobile} />
          </div>
        </Carousel>
      </div>
    </>
  );
}
