import React from "react";
import NavigationHeader from "../../components/NavigationHeader/navigation-header";
import { Col, Container, Row } from "react-bootstrap";
import { theme } from "../../constants/theme";
import { allServicesData } from "../../components/Services/services-data";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import "./servicesView.css";

export default function ServicesView() {
  return (
    <div>
      <NavigationHeader title="Our Services" />
      <div className="services-container">
        <Container>
          <Row className="service-row">
            {allServicesData.map((e) => (
              <Col sm={12} md={6} lg={6} xl={4} className="service-col">
                <img src={e.source} alt="service" className="service-img" />
                <div
                  className={
                    e.subHeading != ""
                      ? "textual-data"
                      : "textual-data-no-subheading"
                  }
                >
                  <p
                    className="title-text"
                    style={{
                      fontFamily: theme.fontFamily.bold,
                      color: theme.color.darkText,
                    }}
                  >
                    {e.title}
                  </p>
                  <p
                    className="subheading-text"
                    style={{
                      fontFamily: theme.fontFamily.regular,
                    }}
                  >
                    {e.subHeading}
                  </p>
                </div>
                <Link
                  to={e.route}
                  className="read-more-btn"
                  style={{
                    borderColor: theme.color.primary,
                    color: theme.color.primary,
                    fontFamily: theme.fontFamily.bold,
                    borderWidth: 1,
                    borderStyle: "solid",
                    marginBottom: 50,
                  }}
                >
                  Read more
                  <FaArrowRight size={12} className="icon-right" />
                </Link>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  );
}
