import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ServicesHeader from "../../../components/NavigationHeader/services-header";
import NotFromPatna from "../../../components/NotFromPatna/notFromPatna";
import { theme } from "../../../constants/theme";
import Image1 from "../../../assets/images/xray1.png";
import Image2 from "../../../assets/images/xray2.png";
export default function KneeReplacementView() {
  return (
    <div>
      <ServicesHeader title="Knee Replacement" />
      <div className="service-list-container">
        <Container>
          <Row className="row-1">
            <div className="text-block">
              <h3
                className="heading-data"
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.headingText,
                  textAlign: "center",
                  marginBottom: 45,
                }}
              >
                About Knee Replacement
              </h3>
              <Row className="item-row">
                <Col xl={9} lg={9} md={6} sm={6}>
                  <p
                    className="desc-text"
                    style={{
                      fontFamily: theme.fontFamily.light,
                      color: theme.color.lightText,
                      marginBottom: 12,
                    }}
                  >
                    Knee Replacement surgery replaces the worn out cartilage of
                    knee joint either as a result of arthritis or injury. This
                    is also called Knee Arthroplasty. Articular cartilage is the
                    smooth shock absorbing lining of the bones of the knee
                    joint.
                  </p>
                  <p
                    className="listHeading"
                    style={{
                      fontFamily: theme.fontFamily.bold,
                      color: theme.color.black,
                      marginBottom: 12,
                      marginTop: 16,
                      fontSize: 16,
                    }}
                  >
                    Types of Knee Replacement:
                  </p>
                  <ul>
                    <li
                      style={{
                        fontFamily: theme.fontFamily.light,
                        color: theme.color.lightText,
                        marginBottom: 20,
                        textAlign: "justify",
                      }}
                      className="listItem"
                    >
                      <span
                        style={{
                          fontFamily: theme.fontFamily.regular,
                          color: theme.color.lightText,
                          textAlign: "justify",
                        }}
                      >
                        Total Knee Replacement :
                      </span>{" "}
                      All three compartments of the knee are replaced during a
                      total knee replacement. This is the most common type of
                      knee replacement. The design usually comprises of: The
                      femoral component (made of a highly polished strong
                      metal), the tibial component (made of a durable plastic,
                      often held in a metal tray), and the patellar component
                      (also plastic).
                    </li>
                    <li
                      style={{
                        fontFamily: theme.fontFamily.light,
                        color: theme.color.lightText,
                        marginBottom: 20,
                        textAlign: "justify",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: theme.fontFamily.regular,
                          color: theme.color.lightText,
                          textAlign: "justify",
                        }}
                      >
                        Partial Knee Replacement:
                      </span>{" "}
                      A partial knee replacement, also known as a
                      uni-compartmental knee replacement, can be performed if
                      damage to the knee is limited to a single part, or
                      compartment, of the knee. The knee replacement can be
                      inserted through a relatively small incision (around
                      3″-4″) without interrupting the main muscle controlling
                      the knee. Because of this, rehabilitation and return to
                      normal activities are faster than after a total knee
                      replacement. Dr Das exclusively utilises US or European
                      imported Knee replacement implants with an excellent
                      published track record.
                    </li>
                    <li
                      style={{
                        fontFamily: theme.fontFamily.light,
                        color: theme.color.lightText,
                        marginBottom: 20,
                        textAlign: "justify",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: theme.fontFamily.regular,
                          color: theme.color.lightText,
                          textAlign: "justify",
                        }}
                      >
                        Single Stage Bilateral Knee Replacement:
                      </span>{" "}
                      Both knee replacement done at the same time. Usually
                      reserved for medically fit patients to avoid need of
                      second anaesthetic.
                    </li>
                  </ul>
                </Col>
                <Col xl={3} lg={3} md={6} sm={6}>
                  <img
                    src={Image1}
                    alt="service"
                    className="item-img"
                    style={{
                      marginLeft: 20,
                    }}
                  />
                </Col>
              </Row>
              <Row className="item-row">
                <Col xl={9} lg={9} md={6} sm={6}>
                  <p
                    style={{
                      fontFamily: theme.fontFamily.bold,
                      color: theme.color.black,
                      marginBottom: 12,
                      marginTop: 16,
                      fontSize: 16,
                    }}
                    className="listHeading"
                  >
                    Is it necessary to have a Knee replacement?
                  </p>
                  <p
                    className="desc-text"
                    style={{
                      fontFamily: theme.fontFamily.light,
                      color: theme.color.lightText,
                      marginBottom: 12,
                    }}
                  >
                    Advanced osteoarthritis of knee is a painful condition
                    limiting the ability of patients to perform activities of
                    daily living like walking, climbing stairs and exercises.
                    This leads to deterioration in quality of life. Arthritic
                    knee can give way leading to falls and fractures. Rheumatoid
                    arthritis and post injury arthritis can also lead to similar
                    picture needing knee replacement surgery.
                  </p>
                  <p
                    style={{
                      fontFamily: theme.fontFamily.bold,
                      color: theme.color.black,
                      marginBottom: 12,
                      marginTop: 16,
                      fontSize: 16,
                    }}
                    className="listHeading"
                  >
                    What happens during Knee Replacement Surgery?
                  </p>
                  <p
                    className="desc-text"
                    style={{
                      fontFamily: theme.fontFamily.light,
                      color: theme.color.lightText,
                      marginBottom: 12,
                    }}
                  >
                    The procedure is performed by numbing the legs – spinal
                    anaesthesia or asleep – general anaesthesia. This will be
                    discussed by your anaesthesiologist. The procedure usually
                    takes one or two hours. Dr Das will remove the damaged
                    cartilage and bone and then position the new metal and
                    plastic joint surfaces to restore the alignment and function
                    of your knee. He exclusively utilises US or European
                    imported Knee replacement implants with an excellent
                    published track record. You might have to stay in hospital
                    for a few days depending upon your rehab and physiotherapy
                    which is supervised by trained physiotherapists.{" "}
                    <span
                      style={{
                        fontFamily: theme.fontFamily.medium,
                        color: theme.color.lightText,
                      }}
                    >
                      Usually patients are able to walk on the same day as Dr
                      Das uses a special technique called Enhanced Recovery
                      Protocol (ERP).{" "}
                    </span>
                  </p>
                  <p
                    style={{
                      fontFamily: theme.fontFamily.bold,
                      color: theme.color.black,
                      marginBottom: 12,
                      marginTop: 16,
                      fontSize: 16,
                    }}
                    className="listHeading"
                  >
                    What is the cost of knee replacement Surgery?
                  </p>
                  <p
                    className="desc-text"
                    style={{
                      fontFamily: theme.fontFamily.light,
                      color: theme.color.lightText,
                      marginBottom: 12,
                    }}
                  >
                    Dr Das exclusively utilises US or European imported Knee
                    replacement implants with an excellent published track
                    record. He has best surgical outcome with a very high
                    patient satisfaction rate. This is provided at an affordable
                    package cost which includes surgery, hospital stay, implants
                    and physiotherapy. Please feel free to contact us for to
                    know about the knee replacement package.
                  </p>
                </Col>
                <Col xl={3} lg={3} md={6} sm={6}>
                  <img
                    src={Image2}
                    alt="service"
                    className="item-img"
                    style={{
                      marginLeft: 20,
                    }}
                  />
                </Col>
              </Row>
            </div>
            <NotFromPatna />
          </Row>
        </Container>
      </div>
    </div>
  );
}
