import React from "react";
import { Container, Row } from "react-bootstrap";
import ServicesHeader from "../../../components/NavigationHeader/services-header";
import NotFromPatna from "../../../components/NotFromPatna/notFromPatna";
import { theme } from "../../../constants/theme";

export default function ElbowReplacementView() {
  return (
    <div>
      <ServicesHeader title="Elbow Replacement" />
      <div className="service-list-container">
        <Container>
          <Row className="row-1">
            <div className="text-block">
              <h3
                className="heading-data"
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.headingText,
                  textAlign: "center",
                  marginBottom: 45,
                }}
              >
                About Elbow Replacement
              </h3>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Elbow Replacement surgery replaces the worn out cartilage of
                knee joint either as a result of arthritis or injury. This is
                also called Elbow Arthroplasty. Articular cartilage is the
                smooth shock absorbing lining of the bones of the elbow joint.
              </p>
              <p
                className="listHeading"
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
              >
                Types of Elbow Replacement:
              </p>
              <ul>
                <li
                  style={{
                    fontFamily: theme.fontFamily.light,
                    color: theme.color.lightText,
                    marginBottom: 20,
                    textAlign: "justify",
                  }}
                  className="listItem"
                >
                  <span
                    style={{
                      fontFamily: theme.fontFamily.regular,
                      color: theme.color.lightText,
                      textAlign: "justify",
                    }}
                  >
                    Total Elbow Replacement :
                  </span>{" "}
                  The main elbow joint formed by the end of arm bone (humerus)
                  and forearm bone (ulna) is replaced by highly polished strong
                  metal. The radial head may or may not be replaced. Dr Das
                  exclusively utilises US or European imported Elbow replacement
                  implants with an excellent published track record.
                </li>
                <li
                  style={{
                    fontFamily: theme.fontFamily.light,
                    color: theme.color.lightText,
                    marginBottom: 20,
                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontFamily: theme.fontFamily.regular,
                      color: theme.color.lightText,
                      textAlign: "justify",
                    }}
                  >
                    Hemiarthroplasty/ Partial Elbow Replacement:
                  </span>{" "}
                  The is performed in cases of non-fixable complex elbow
                  fracture. The fractured distal humerus is replaced with highly
                  polished metal. Proximal ulna is not replaced.
                </li>
                <li
                  style={{
                    fontFamily: theme.fontFamily.light,
                    color: theme.color.lightText,
                    marginBottom: 20,
                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontFamily: theme.fontFamily.regular,
                      color: theme.color.lightText,
                      textAlign: "justify",
                    }}
                  >
                    Radial Head Replacement:
                  </span>{" "}
                  Radial head is a knob of bone which helps rotate the forearm.
                  If this is fractured and can’t be fixed then radial head is
                  replaced with highly polished metal.
                </li>
              </ul>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Is it necessary to have a Elbow replacement?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Advanced osteoarthritis of elbow is a painful condition limiting
                the ability of patients to perform activities of daily living.
                This leads to deterioration in quality of life. Rheumatoid
                arthritis and post injury arthritis can also lead to similar
                picture needing elbow replacement surgery.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What happens during Elbow Replacement Surgery?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                The procedure is performed with patient asleep – general
                anaesthesia. This will be discussed by your anaesthesiologist.
                The procedure usually takes one or two hours. Dr Das will remove
                the damaged cartilage and bone and then position the new metal
                joint surfaces to restore the alignment and function of your
                elbow. He exclusively utilises US or European imported elbow
                replacement implants with an excellent published track record.
                You might have to stay in hospital for a few days depending upon
                your rehab and physiotherapy which is supervised by trained
                physiotherapists.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What is the cost of elbow replacement Surgery?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Dr Das exclusively utilises US or European imported hip
                replacement implants with an excellent published track record.
                He has best surgical outcome with a very high patient
                satisfaction rate. This is provided at an affordable package
                cost which includes surgery, hospital stay, implants and
                physiotherapy. Please feel free to contact us for to know about
                the elbow replacement package.
              </p>
            </div>
            <NotFromPatna />
          </Row>
        </Container>
      </div>
    </div>
  );
}
