import Accordion from "react-bootstrap/Accordion";
import "./accordion-styles.css";
function AboutAccordion() {
  const qualificationData = [
    {
      degree: "FRCS Trauma & Orth, England",
      institute: "Intercollegiate, Royal college of Surgeons of England",
      year: "2018",
    },
    {
      degree: "FEBOT Trauma & Orth, Germany",
      institute:
        "Fellowship of European Board of Trauma & Orthpaedics, Berlin, Germany",
      year: "2018",
    },
    {
      degree: "MCh Trauma & Orth, UK",
      institute: "Edgehill University, Lancashire, UK",
      year: "2016",
    },
    {
      degree: "MRCS, Glasgow",
      institute:
        "Intercollegiate, Royal college of Physician and Surgeons of Glasgow, UK",
      year: "2013",
    },
    {
      degree: "DNB Orthopaedics, India",
      institute: "Govt of India",
      year: "2010",
    },
    {
      degree: "MS Orthopaedics, Mumbai",
      institute: "Lokmanya Tilak Municipal Medical College, Sion, Mumbai",
      year: "2009",
    },
    {
      degree: "MBBS PMCH, Patna",
      institute: "Patna Medical College & Hospital, Patna",
      year: "1999",
    },
  ];
  const specialisationData = [
    {
      content:
        "Keyhole/Arthroscopy of Shoulder, Knee, Elbow & Ankle for sports Injury and Joint Preservation. This includes frozen shoulder, recurrent shoulder dislocation, Rotator cuff tears, ACL, PCL & Muliligament reconstruction, Loose bodies & osteochondral lesion of elbow, ankle and knee joints.",
    },
    {
      content:
        "Joint replacement surgery – Total & Reverse Shoulder Replacement, Partial/ Unicondylar and Total Knee replacement, Cemented and Uncemented Total hip replacement, Total Elbow Replacement",
    },
    {
      content:
        "Revision Shoulder, Knee, Hip & Elbow replacement for Infection, aseptic loosening, etc",
    },
    {
      content:
        "Polytrauma, Open, Complex and periarticular fracture management",
    },
  ];
  const promembershipData = [
    { content: "Fellow of Royal College of Surgeons, England" },
    { content: "General Medical Council, UK" },
    { content: "AO Trauma Europe" },
    { content: "British Shoulder and Elbow Society" },
    { content: "Indian Medical Register" },
    { content: "Bombay Orthopaedic Society" },
  ];
  const fellowshipData = [
    {
      content:
        "Senior Upper Limb Fellowship (29-09-2020 till 05-10-2021) – Harrogate and District NHS Foundation Trust, Harrogate, UK HG2 7SX. The fellowship encompasses all aspects of upper limb surgery including sports medicine, arthroscopy of shoulder and elbow and Joint replacement",
    },
    {
      content:
        "Speciality Doctor with Fellowship in trauma surgery, Sports medicine and knee and ankle arthroscopy and Hip and knee Replacement surgery (2014 -2021) – Airedale NHS Foundation Trust, Keighley, UK",
    },
    {
      content:
        "Upper Limb Fellowship at Wrightington Hospital, UK (Centre of Excellance) (01-08-2018 – 05-02-2019). This fellowship encompasses all aspects of upper limb surgery including sports medicine, arthroscopy of shoulder and Joint replacement",
    },
    {
      content:
        "Knee Fellowship at Wrightington Hospital, UK (Centre of Excellance) (05-02-2014– 05-08-2014). This fellowship encompasses all aspects of knee surgery including sports Medicine, arthroscopy of knee and Joint replacement",
    },
    {
      content:
        "Knee and Shoulder Arthroscopy – Jaslok Hospital (Mumbai, Maharashtra, India) July - December, 2009.",
    },
  ];
  const publicationData = [
    {
      content:
        "Outpatient Neuromodulation may avert the need for surgery in patients with painful cutaneous neuromas; Abhishek K Das, Chye Yew Ng; Journal of Hand Surgery – Asian Pacific 2021 Sep 26(3): 390-395. Pubmed ID: 34380411 DOI: 10.1142/S2424835521500375",
    },
    {
      content:
        "Does Posterior half wedge augmented glenoid restore version and alignment in Total Shoulder Arthroplasty for the B2 Glenoid?; Abhishek K Das, Andrew C Wright, Jagwant Singh, Puneet Monga; Journal of Clinical Orthopaedics & Trauma 2020 Mar;11(Suppl 2):S275-S279. DOI: 10.1016/j.jcot.2020.02.005. Epub 2020 Feb 14. Pubmed ID: 32189953",
    },
    {
      content:
        "Suture repair of simple transverse olecranon fractures and chevron olecranon osteotomy;  Abhishek K Das, A Jariwala, Adam C Watts. Techniques of hand and Upper Extremity Surgery 2016 Mar 20(1):1-5. DOI: 10.1097/BTH.0000000000000106. Pubmed ID: 26630656",
    },
    {
      content:
        "Metacarpal head resection for treatment of the finger- in- Palm deformity in longstanding neurological injury;  Abhishek K Das, S C Talwalkar, S R Murali. Journal of hand Surgery European volume 2015 Mar;40(3):319-20. DOI 10.1177/1753193413520278. Epub 2014 Jan 17. Pubmed ID: 24442666",
    },
    {
      content:
        "Septic arthritis of the sterno-clavicular joint as a cause of dysphagia: A report of two cases and review of literature;  Abhishek K Das, Puneet Monga. Clinical Rheumatology 2014 Jan;33(1):141-43. DOI 10.1007/s10067-013-2437-6. Epub 2013 Nov 19. Pubmed ID: 24249147",
    },
    {
      content:
        "Sandwich reconstruction technique for subchondral giant cell tumors around the knee. Samik Banerjee, K.K. Sabui, R. Chatterjee, Abhishek K.Das, A. Mondal, Dilip K. Pal; Current Orthopaedics Practice, 2012 Sep;12(5):459-66. DOI: 10.1097/BCO.0b013e3182640f1a",
    },
    {
      content:
        "Anatomical reconstruction of reverse Hill Sachs lesions using the underpinning technique; Samik Banerjee, Vinay Singh, Abhishek K Das, Vipul Patel; Orthopaedics, 2012 May;35(5):e752 -57. DOI: 10.3928/01477447-20120426-35. Pubmed ID: 22588422",
    },
    {
      content:
        "Percutaneous Pinning For Extra-Articular Fractures of Distal Radius: A New   Perspective;  Abhishek K Das, Nand K Sundaram, T Suresh, G T Prasad; Indian Journal of Orthopaedics. 2011 Sep-Oct;45(5):422-6. DOI: 10.4103/0019-5413.83949. Pubmed ID: 21886923",
    },
  ];
  const disserectionData = [
    {
      content:
        "Systematic review of fixation versus arthroplasty for radial head fractures in terrible triad injuries of the elbow: successfully submitted dissertation to the Edgehill university, UK as part of MCh degree Trauma & Orthopaedics in 2016.",
    },
    {
      content:
        "Functional outcome of various modalities of treatment for Supracondylar Femur Fractures: successfully submitted dissertation to Maharashtra University of Health Sciences, India as part of MS Orthopaedics degree programme.",
    },
  ];
  const presentationData = [
    {
      content:
        "“Does Posterior half wedge augmented glenoid restore version and alignment in Total Shoulder Arthroplasty for the B2 Glenoid’’ A K Das, A C Wright, J Singh, P Monga. Poster presentation at European Society for Surgery of the Shoulder and the Elbow (SECEC-ESSSE), Copenhagen, Denmark, 30 August – 1 September, 2019.",
    },
    {
      content:
        "“Outpatient Neuromodulation may avert the need for surgery in patients with painful cutaneous neuromas’’ A K Das, C Y Ng. Podium presentation at Triennial congress International Federation of Societies for Surgery of Hand (IFSSH & IFSTH), Berlin, Germany, 20 June, 2019.",
    },
    {
      content:
        "“Role of MRI in ‘suspected’ scaphoid fractures” A K Das, V Ramasamy, N Little, N Ellahee. Poster presentation at International Society of Orthopaedic Surgery and Traumatology (SICOT) Conference, Rio de Janerio, Brazil, 19-22, November, 2014.",
    },
    {
      content:
        "“Audit of the role of MRI in ‘suspected’ scaphoid fractures” A K Das, V Ramasamy, N Little, N Ellahee. Poster presentation at European Federation of National Associations of Orthopaedics and Traumatology (EFORT) Conference, London, UK, 04-06, June, 2014.",
    },
    {
      content:
        "“The importance of patient expectations in predicting functional outcomes after shoulder arthroscopic capsular release for Adhesive Capsulitis”  R Dattani,  V Ramasamy , S Thompson , A K Das , V R Patel. Podium presentation at European Federation of National Associations of Orthopaedics and Traumatology (EFORT) Conference, Istanbul, Turkey, 07 June, 2013.",
    },
    {
      content:
        "“Use of coblation technique in early osteoarthritis knee” A K Das, N K Sundaram. Podium presentation at Madras Orthopaedic Society Meet, Chennai, India, 06 May, 2010. ",
    },
    {
      content:
        "“Outpatient Neuromodulation may avert the need for surgery in patients with painful cutaneous neuromas’’ A K Das, C Y Ng. Poster presentation at British Society for Surgery of Hand (BSSH-IHSS) Autumn Meet, Dublin, 30 October – 01 November, 2019.",
    },
    {
      content:
        "“Suture repair of simple transverse olecranon fracture or olecranon osteotomy” A K Das, A Jariwala, A C Watts. Podium presentation at British Elbow and Shoulder Society (BESS) Conference, Nottingham, 26 June, 2014.",
    },
    {
      content:
        "“Audit of the role of MRI in ‘suspected’ scaphoid fractures” A K Das, V Ramasamy, N Little, N Ellahee. Podium presentation at British Society for Surgery of Hand (BSSH) Spring Meet, Newcastle – Gateshed, 01 May, 2014.",
    },
    {
      content:
        "“Does Posterior half wedge augmented glenoid restore version and alignment in Total Shoulder Arthroplasty for the B2 Glenoid’’ R Dhir, A K Das, A C Wright, J Singh, P Monga. Podium presentation at Wrightington Gold Medal Day, Wrightington Hospital, 7 June, 2019.",
    },
    {
      content:
        "“Systematic review of Fixation versus ORIF for radial head fractures in terrible triad injuries of elbow” A K Das, P Leadbetter, A C Watts Podium presentation at Summer Ridings Meeting, Airedale General Hospital, 19 May, 2017.        ",
    },
    {
      content:
        "“Metacarpal head resection for treatment of the finger- in- palm deformity in longstanding neurological injury” A K Das, S Venkatachalam, R Prmar, S C Talwalkar, S R Murali. Podium presentation at Wrightington Gold Medal day, Wrightington Hospital, 14 June, 2013.",
    },
  ];
  const clinicalAuditsData = [
    {
      content:
        "Service Evaluation of US Guided Barbotage for calcific tendonitis of Rotator Cuff - Presented at Harrogate District Hospital, Harrogate District NHS Foundation Trust. A K Das, C Talbot – September 2021",
    },
    {
      content:
        "Management of Supracondylar Humerus fractures in children against BOAST 11 Guidelines and previous recommendations – Re-audit. Presented at Airedale General Hospital, Airedale NHS Foundation Trust. A K Das, S King, D Bowe – January 2020 ",
    },
    {
      content:
        "Service Evaluation of Wide Awake Local Anaesthesia No Tourniquet technique for Hand Surgery. Presented at Wrightington Hospital, WWL NHS foundation Trust. A K Das, S Akhtar - January, 2019.",
    },
    {
      content:
        "Re-Audit of the management of shoulder dislocation according to the BESS Guidelines. Presented at Airedale General Hospital, Airedale NHS Foundation Trust. A K Das, D Bowe - July, 2018",
    },
    {
      content:
        "Weekend Trauma and Orthopaedics Cases Requiring Theatre, Presented at Airedale General Hospital, Airedale NHS Foundation Trust. L Evans, N Suri, A K Das, G Thomas, January, 2017.",
    },
    {
      content:
        "Audit on Delay in Discharge of Adult trauma patients, Presented at Airedale General Hospital, Airedale NHS Trust D Alexander, B Heath, A K Das, F Raja, G Thomas, February 2015",
    },
    {
      content:
        "Readmissions for Neck of femur fractures, Presented at Wrightington Hospital, WWL NHS Foundation Trust, M Khan, A K Das, A Watts, May, 2014.",
    },
    {
      content:
        "Lateral radiograph of wrist: Missed injuries, Presented at Wrightington Hospital, WWL NHS Foundation Trust, A K Das, S C Talwalkar, September, 2013.",
    },
    {
      content:
        "Platelet rich plasma injection for tennis elbow, Presented at Wrightington Hospital, WWL NHS Foundation Trust, A Bidwai, A K Das, A Watts, July, 2013.",
    },
    {
      content:
        "Scaphoid fracture pathway: Role of MRI, Presented at St Helier Hospital, Epsom and St Helier University Hospitals NHS Trust  A K Das, V Ramasamy, N Little, N Ellahee, September, 2012.",
    },
    {
      content:
        "Distal radius fractures: Quality of care from A&E to fracture clinic, Presented at St Helier Hospital, Epsom and St Helier University Hospitals NHS Trust, A K Das, S Horriat, May, 2012.",
    },
  ];
  const teachingData = [
    {
      content:
        "Faculty at Wrightington Basic Science and Upper Limb FRCS Trauma & Orth exam revision course since 2018. This course has been designed to help the trainees revise for the upper limb and basic science portion of the FRCS (T&O) curriculum.",
    },
    {
      content:
        "Active participation in Orthopaedic teaching at Airedale NHS Foundation Trust which includes undergraduate medical students teaching.",
    },
  ];
  return (
    <Accordion className="about-accordion">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Medical Qualification</Accordion.Header>
        <Accordion.Body>
          <ul>
            {qualificationData.map((e) => (
              <>
                <li>
                  {e.degree} - {e.institute} ({e.year})
                </li>
              </>
            ))}
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Specialisation</Accordion.Header>
        <Accordion.Body>
          <ul>
            {specialisationData.map((e) => (
              <>
                <li>{e.content}</li>
              </>
            ))}
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Professional Membership</Accordion.Header>
        <Accordion.Body>
          <ul>
            {promembershipData.map((e) => (
              <>
                <li>{e.content}</li>
              </>
            ))}
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>Fellowship and Work Experience</Accordion.Header>
        <Accordion.Body>
          <ul>
            {fellowshipData.map((e) => (
              <>
                <li>{e.content}</li>
              </>
            ))}
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>
          International and National Publications
        </Accordion.Header>
        <Accordion.Body>
          <ul>
            {publicationData.map((e) => (
              <>
                <li>{e.content}</li>
              </>
            ))}
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header>Dissertations</Accordion.Header>
        <Accordion.Body>
          <ul>
            {disserectionData.map((e) => (
              <>
                <li>{e.content}</li>
              </>
            ))}
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="7">
        <Accordion.Header>
          International and National Presentations
        </Accordion.Header>
        <Accordion.Body>
          <ul>
            {presentationData.map((e) => (
              <>
                <li>{e.content}</li>
              </>
            ))}
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="8">
        <Accordion.Header>International Clinical Audits</Accordion.Header>
        <Accordion.Body>
          <ul>
            {clinicalAuditsData.map((e) => (
              <>
                <li>{e.content}</li>
              </>
            ))}
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="9">
        <Accordion.Header>Teaching and Training</Accordion.Header>
        <Accordion.Body>
          <ul>
            {teachingData.map((e) => (
              <>
                <li>{e.content}</li>
              </>
            ))}
          </ul>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default AboutAccordion;
