export const videoData = [
  {
    id: 0,
    link: "https://www.youtube.com/embed/ef8BvW6umfo",
  },
  {
    id: 1,
    link: "https://www.youtube.com/embed/m8LDBlZN-XM",
  },
];
