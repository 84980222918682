import React, { useState } from "react";
import NavigationHeader from "../../components/NavigationHeader/navigation-header";
import { useForm, ValidationError } from "@formspree/react";
import "./contact.css";
import { Alert, Button, Container, Row, Col, Card } from "react-bootstrap";
import { theme } from "../../constants/theme";

export default function ContactView() {
  const [state, handleSubmit] = useForm("meqwevbg");
  const [show, setShow] = useState(true);

  return (
    <>
      <NavigationHeader title="Contact" />
      <Container>
        {state.succeeded ? (
          <Alert show={show} variant="success" style={{ marginTop: 12 }}>
            <Alert.Heading
              style={{
                fontFamily: "PlusJakartaDisplay-Medium, sans-serif",
              }}
            >
              Mail Sent Successfully
            </Alert.Heading>
            <p
              style={{
                fontFamily: "PlusJakartaDisplay-Regular, sans-serif",
              }}
            >
              Our team will respond to your email as soon as possible. You can
              use our chatbot to communicate directly with us. You can access it
              by clicking on the chat icon on your bottom right corner of the
              screen.
            </p>
            <hr />
            <div className="d-flex justify-content-end">
              <Button
                onClick={() => setShow(false)}
                variant="outline-success"
                style={{
                  fontFamily: "PlusJakartaDisplay-Medium, sans-serif",
                  fontSize: 14,
                }}
              >
                Close me
              </Button>
            </div>
          </Alert>
        ) : null}
        <Row style={{ alignItems: "center", justifyContent: "center" }}>
          <Col lg={6} md={12}>
            <div style={{ marginTop: 8, marginBottom: 8 }}>
              <iframe
                width="100%"
                height="500"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3597.5060915803133!2d85.1234987!3d25.6213236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ed5836ebd76c3b%3A0x28bbb5f601e636e1!2sShoulder%20Knee%20Specialist!5e0!3m2!1sen!2sin!4v1675328501816!5m2!1sen!2sin"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
          </Col>
          <Col lg={6} md={12}>
            <div style={{ marginTop: 8, marginBottom: 8 }}>
              <Card>
                <Card.Subtitle
                  style={{
                    marginTop: 12,
                    marginLeft: 10,
                    marginRight: 10,
                    fontSize: 14,
                    color: "rgba(0,0,0,0.4)",
                    fontFamily: "PlusJakartaDisplay-Regular, sans-serif",
                  }}
                >
                  You can fill this form to send a mail. You can use our chatbot
                  to communicate directly with us. You can access it by clicking
                  on the chat icon on your bottom right corner of the screen.
                </Card.Subtitle>
                <hr />
                <Card.Body>
                  <form onSubmit={handleSubmit} className="contact-form">
                    <label htmlFor="name">Your Name</label>
                    <input id="name" type="text" name="name" />
                    <ValidationError
                      prefix="Name"
                      field="name"
                      errors={state.errors}
                    />
                    <label htmlFor="email">Email Address</label>
                    <input id="email" type="email" name="email" />
                    <ValidationError
                      prefix="Email"
                      field="email"
                      errors={state.errors}
                    />
                    <label htmlFor="message">Message</label>
                    <textarea id="message" type="text" name="message" />
                    <ValidationError
                      prefix="Message"
                      field="message"
                      errors={state.errors}
                    />
                    <button
                      type="submit"
                      disabled={state.submitting}
                      className="submit-btn"
                      style={{
                        borderColor: theme.color.primary,
                        color: theme.color.primary,
                        fontFamily: theme.fontFamily.bold,
                        borderWidth: 1,
                        borderStyle: "solid",
                        marginBottom: 50,
                        height: 36,
                      }}
                    >
                      Submit
                    </button>
                  </form>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
