import React from "react";
import Layout from "./Layout/Layout";
import "./App.css";

export default function App() {
  return (
    <div>
      <Layout />
    </div>
  );
}
