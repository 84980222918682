import React from "react";
import NavigationHeader from "../../components/NavigationHeader/navigation-header";
import {
  galleryDataDesktop,
  galleryDataMobile,
} from "../../components/Gallery/gallery-data";
import "./galleryView.css";

export default function GalleryView() {
  return (
    <div>
      <NavigationHeader title="Gallery" />
      <div className="content">
        <div className="mobile-testimonial">
          <div className="mobile-gallery">
            {galleryDataMobile.map((e) => (
              <div key={e.id}>
                <img src={e.source} alt="gallery" className="desktop-img" />
              </div>
            ))}
          </div>
        </div>
        <div className="desktop-testimonial">
          <div className="desktop-gallery">
            {galleryDataDesktop.map((e) => (
              <div key={e.id}>
                <img src={e.source} alt="gallery" className="desktop-img" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
