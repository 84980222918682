import React from "react";
import "./about.css";
import AboutPicture from "../../assets/images/about.png";
import { Col, Container, NavLink, Row } from "react-bootstrap";
import { theme } from "../../constants/theme";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
export default function About() {
  return (
    <div className="about-container">
      <Container>
        <Row>
          <Col sm={12} md={12} lg={5} xl={4}>
            <div className="image-block">
              <img src={AboutPicture} alt="about" className="about-img" />
            </div>
          </Col>
          <Col sm={12} md={12} lg={7} xl={8}>
            <div className="text-block">
              <h3
                className="heading-data"
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.headingText,
                }}
              >
                Know Your Doctor
              </h3>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                }}
              >
                Dr Abhishek Kumar Das is a Senior Orthopaedic Consultant at Big
                Apollo Spectra Hospital, Patna. His name is synonymous with
                clinical excellence and patient centred approach. He passed MBBS
                from PMCH Patna and MS Orthopaedics from Lokmanya Tilak
                Municipal Medical College (LTMMC) Sion, Mumbai followed by DNB
                Orthopaedics. He has extensively trained in Orthopaedic
                subspecialities in UK with knee and Senior upper limb
                fellowships at world renowned Centre of Excellence Wrightington
                Hospital, UK. During his pursuit for academic excellence, he
                achieved Intercollegiate MRCS from RCPSG, Glasgow, MCh from
                Edgehill University, UK and Intercollegiate FRCS (Trauma & Orth)
                UK. This enabled him to attain the coveted CESR, Trauma & Orth,
                UK. He has also completed European Board Fellowship in
                Orthopaedics, FEBOT, Berlin, Germany.
              </p>
              <Link
                to="about"
                className="know-more-btn"
                style={{
                  borderColor: theme.color.primary,
                  color: theme.color.primary,
                  fontFamily: theme.fontFamily.bold,
                  borderWidth: 1,
                  borderStyle: "solid",
                }}
              >
                Read more about Dr Abhishek
                <FaArrowRight size={12} className="icon-right" />
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
