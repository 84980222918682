import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import Logo from "../../assets/images/logo.svg";
import Dot from "../../assets/images/dot.svg";
import { theme } from "../../constants/theme";
import NavigationMenu from "../NavigationMenu/NavigationMenu";
import "./header.css";
import { FaBars } from "react-icons/fa";
import { menuItems } from "../NavigationMenu/MenuItems";
import { NavLink, Link } from "react-router-dom";

export default function Header() {
  const [toggle, setToggle] = useState(false);
  let activeStyle = {
    fontFamily: theme.fontFamily.medium,
    color: theme.color.primary,
    borderBottomWidth: 2,
    borderBottomStyle: "solid",
    borderBottomColor: theme.color.primary,
  };
  return (
    <>
      <Navbar expand="lg" style={{ background: theme.color.primaryLightest }}>
        <Container fluid>
          <Navbar.Brand href="#home" className="navbarBrand">
            <div className="row-center">
              <img src={Logo} alt="logo" className="logo-img" />
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.darkText,
                }}
                className="logoText"
              >
                Shoulder Knee Specialist
              </p>
            </div>
          </Navbar.Brand>
          <div className="toggle-mobile" onClick={() => setToggle(!toggle)}>
            <FaBars size={22} color={theme.color.primary} />
          </div>
          <div
            className={
              !toggle
                ? "navigationListToggled toggleDisplay"
                : "navigationListToggled"
            }
          >
            {menuItems.map((e) => (
              <>
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "navigationItemsToggled"
                      : "navigationItemsToggled link-underline"
                  }
                  key={e.id}
                  style={({ isActive }) =>
                    isActive
                      ? activeStyle
                      : {
                          fontFamily: theme.fontFamily.regular,
                          color: theme.color.black,
                        }
                  }
                  to={e.path}
                  onClick={() => setToggle(!toggle)}
                >
                  {e.title}
                </NavLink>
              </>
            ))}
            <Link
              to="/book-appointment"
              style={{
                backgroundColor: theme.color.primary,
                color: theme.color.white,
                fontFamily: theme.fontFamily.bold,
                textDecoration: "none",
              }}
              className="book-btn"
              onClick={() => setToggle(!toggle)}
            >
              Book an appointment
            </Link>
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="navigation-section">
              <Nav.Link
                href="mailto:info@shoulderkneespecialist.com?Subject=Hello"
                target="_top"
                style={{
                  fontFamily: theme.fontFamily.regular,
                  color: theme.color.darkText,
                  fontSize: 14,
                }}
                className="link_underline"
              >
                info@shoulderkneespecialist.com
              </Nav.Link>
              <img src={Dot} alt="dot" />
              <Nav.Link
                href="tel:+919523432866"
                style={{
                  fontFamily: theme.fontFamily.regular,
                  color: theme.color.darkText,
                  fontSize: 14,
                }}
                className="link_underline"
              >
                +91 9523432866
              </Nav.Link>
              <Link
                to="/book-appointment"
                style={{
                  backgroundColor: theme.color.primary,
                  color: theme.color.white,
                  fontFamily: theme.fontFamily.bold,
                  textDecoration: "none",
                }}
                className="book-btn"
              >
                Book an appointment
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Nav className="navigation-section mobile-navigation">
        <Nav.Link
          href="mailto:info@shoulderkneespecialist.com?Subject=Hello"
          target="_top"
          style={{
            fontFamily: theme.fontFamily.regular,
            color: theme.color.darkText,
            fontSize: 14,
          }}
          className="link_underline"
        >
          info@shoulderkneespecialist.com
        </Nav.Link>
        <img src={Dot} alt="dot" />
        <Nav.Link
          href="tel:+919523432866"
          style={{
            fontFamily: theme.fontFamily.regular,
            color: theme.color.darkText,
            fontSize: 14,
          }}
          className="link_underline"
        >
          +91 9523432866
        </Nav.Link>
      </Nav>
      <NavigationMenu toggle={toggle} />
    </>
  );
}
