import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { theme } from "../../constants/theme";
import { servicesData } from "./services-data";
import "./services.css";
export default function Services() {
  return (
    <div className="services-container">
      <Container>
        <Row>
          <h1
            className="heading-text"
            style={{
              fontFamily: theme.fontFamily.bold,
              color: theme.color.headingText,
            }}
          >
            Our Services
          </h1>
        </Row>
        <Row className="service-row">
          {servicesData.map((e) => (
            <Col sm={12} md={6} lg={6} xl={4} className="service-col">
              <img src={e.source} alt="service" className="service-img" />
              <div
                className={
                  e.subHeading != "" ? "text-data" : "text-data-no-subheading"
                }
              >
                <p
                  className="title-text"
                  style={{
                    fontFamily: theme.fontFamily.bold,
                    color: theme.color.darkText,
                  }}
                >
                  {e.title}
                </p>
                <p
                  className="subheading-text"
                  style={{
                    fontFamily: theme.fontFamily.regular,
                  }}
                >
                  {e.subHeading}
                </p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}
