import Achievements from "../../assets/images/achievements.svg";
import Satisfied from "../../assets/images/satisfied.svg";
import Experience from "../../assets/images/experience.svg";
import Replacement from "../../assets/images/jointReplacements.svg";

export const detailsData = [
  {
    id: 0,
    title: "20+ Achievements",
    source: Achievements,
  },
  {
    id: 1,
    title: "1000+ Satisfied Patients",
    source: Satisfied,
  },
  {
    id: 2,
    title: "15+ Years of Experience",
    source: Experience,
  },
  {
    id: 3,
    title: "2500+ Joint Replacements",
    source: Replacement,
  },
];
