import React from "react";
import { Container, Row } from "react-bootstrap";
import ServicesHeader from "../../../components/NavigationHeader/services-header";
import NotFromPatna from "../../../components/NotFromPatna/notFromPatna";
import { theme } from "../../../constants/theme";

export default function HipReplacementView() {
  return (
    <div>
      <ServicesHeader title="Hip Replacement" />
      <div className="service-list-container">
        <Container>
          <Row className="row-1">
            <div className="text-block">
              <h3
                className="heading-data"
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.headingText,
                  textAlign: "center",
                  marginBottom: 45,
                }}
              >
                About Hip Replacement
              </h3>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Shoulder Replacement surgery replaces the worn out cartilage of
                the Hip joint either as a result of arthritis or injury. This is
                also called Hip Arthroplasty. Articular cartilage is the smooth
                shock absorbing lining of the bones of the hip joint.
              </p>
              <p
                className="listHeading"
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
              >
                Types of Hip Replacement:
              </p>
              <ul>
                <li
                  style={{
                    fontFamily: theme.fontFamily.light,
                    color: theme.color.lightText,
                    marginBottom: 20,
                    textAlign: "justify",
                  }}
                  className="listItem"
                >
                  <span
                    style={{
                      fontFamily: theme.fontFamily.regular,
                      color: theme.color.lightText,
                      textAlign: "justify",
                    }}
                  >
                    Total Hip Replacement:
                  </span>{" "}
                  Hip is a ball and socket joint. This is performed when the
                  cartilage of both the ball and socket are worn out.
                  <ul
                    style={{
                      marginTop: 16,
                    }}
                  >
                    <li
                      style={{
                        fontFamily: theme.fontFamily.light,
                        color: theme.color.lightText,
                        marginBottom: 10,
                        textAlign: "justify",
                      }}
                      className="listItem"
                    >
                      <span
                        style={{
                          fontFamily: theme.fontFamily.regular,
                          color: theme.color.lightText,
                          textAlign: "justify",
                        }}
                      >
                        Metal on Poly:
                      </span>{" "}
                      The socket is replaced by high quality plastic or durable
                      plastic in metal socket. The ball is replaced with highly
                      polished metal ball on a metal stem.
                    </li>
                    <li
                      style={{
                        fontFamily: theme.fontFamily.light,
                        color: theme.color.lightText,
                        marginBottom: 10,
                        textAlign: "justify",
                      }}
                      className="listItem"
                    >
                      <span
                        style={{
                          fontFamily: theme.fontFamily.regular,
                          color: theme.color.lightText,
                          textAlign: "justify",
                        }}
                      >
                        Ceramic on Poly:
                      </span>{" "}
                      The socket is replaced by high quality durable plastic in
                      metal socket. The ball is replaced with highly polished
                      ceramic ball on a metal stem.
                    </li>
                    <li
                      style={{
                        fontFamily: theme.fontFamily.light,
                        color: theme.color.lightText,
                        marginBottom: 10,
                        textAlign: "justify",
                      }}
                      className="listItem"
                    >
                      <span
                        style={{
                          fontFamily: theme.fontFamily.regular,
                          color: theme.color.lightText,
                          textAlign: "justify",
                        }}
                      >
                        Ceramic on Ceramic:
                      </span>{" "}
                      The socket is replaced by ceramic in a metal socket. The
                      ball is replaced with highly polished ceramic ball on a
                      metal stem This is a preferred option in younger patients
                      due to better bearing profile.
                    </li>
                  </ul>
                </li>
                <li
                  style={{
                    fontFamily: theme.fontFamily.light,
                    color: theme.color.lightText,
                    marginBottom: 20,
                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontFamily: theme.fontFamily.regular,
                      color: theme.color.lightText,
                      textAlign: "justify",
                    }}
                  >
                    Hemiarthroplasty/ Partial Hip Replacement:
                  </span>{" "}
                  The ball is replaced with highly polished metal ball on a
                  metal stem. The hip socket is not replaced. This is performed
                  in older patients with hip fracture.
                </li>
              </ul>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Is it necessary to have a hip replacement?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Advanced osteoarthritis of hip is a painful condition limiting
                the ability of patients to perform activities of daily living
                like walking, climbing stairs and exercises. This leads to
                deterioration in quality of life. Rheumatoid arthritis and post
                injury arthritis can also lead to similar picture needing hip
                replacement surgery. In elderly patient, hip fracture is serious
                and needs urgent Hip replacement. This is not only to preserve
                their mobility but also to prevent chest infection and pressure
                sore that can happen due to inability to mobilise after hip
                fracture.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What happens during Hip Replacement Surgery?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                The procedure is performed by numbing the legs – spinal
                anaesthesia or asleep – general anaesthesia. This will be
                discussed by your anaesthesiologist. The procedure usually takes
                two hours. Dr Das will remove the damaged cartilage and bone and
                then position the new metal/plastic/ceramic joint surfaces to
                restore the function of your hip. He exclusively utilises US or
                European imported hip replacement implants with an excellent
                published track record. You might have to stay in hospital for a
                few days depending upon your rehab and physiotherapy which is
                supervised by trained physiotherapists.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What is the cost of hip replacement Surgery?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Dr Das exclusively utilises US or European imported hip
                replacement implants with an excellent published track record.
                He has best surgical outcome with a very high patient
                satisfaction rate. This is provided at an affordable package
                cost which includes surgery, hospital stay, implants and
                physiotherapy. Please feel free to contact us for to know about
                the hip replacement package.
              </p>
            </div>
            <NotFromPatna />
          </Row>
        </Container>
      </div>
    </div>
  );
}
