import React from "react";
import { Container, Row } from "react-bootstrap";
import ServicesHeader from "../../../components/NavigationHeader/services-header";
import NotFromPatna from "../../../components/NotFromPatna/notFromPatna";
import { theme } from "../../../constants/theme";

export default function ShoulderArthroscopyView() {
  return (
    <div>
      <ServicesHeader title="Shoulder Arthroscopy" />
      <div className="service-list-container">
        <Container>
          <Row className="row-1">
            <div className="text-block">
              <h3
                className="heading-data"
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.headingText,
                  textAlign: "center",
                  marginBottom: 45,
                }}
              >
                About Shoulder Arthroscopy
              </h3>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Bankart Repair for Shoulder Dislocation
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Shoulder is a ball and socket joint which is large range of
                movement. This makes the joint inherently unstable. One of the
                most important factors that stabilise the shoulder joint include
                Capsulolabral complex. This is torn during shoulder dislocation
                causing Bankart lesion. Every time shoulder dislocates it causes
                damage to humeral hear which is Hill-Sach lesion. It can also
                cause damage the glenoid/ shoulder socket. There are techniques
                to treat a recurrent shoulder dislocation. If there isn’t much
                damage to the shoulder head and glenoid socket, then All
                Arthroscopic repair of Bankart lesion is performed by Dr Das
                which is highly successful. This is followed by his supervised
                rehab programme.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Additional Arthroscopic Procedures for Shoulder Dislocation
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                In certain cases of shoulder dislocation there is fracture of
                the shoulder socket glenoid rim. This is called a Bony Bankart
                Lesion. Dr Das uses All Arthroscopic technique for Bony Bankart
                Repair. In a few cases, especially professional athletes, there
                is a high risk of redislocation after arthroscopic Bankart
                Repair. This is probably due to the fact that small defect in
                the ball of the shoulder joint can still engage with the socket
                rim causing dislocation. In these cases Dr Das uses additional
                arthroscopic technique called ‘REMPLISSAGE’. This is a French
                word meaning ‘To Fill In’. The defect is filled using a suture
                anchor and using part of the rotator cuff tendon called
                Infraspinatus.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Latarjet Technique
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                If there is severe damage to shoulder head and glenoid socket
                then an open Laterjet technique is preferred. This is French
                Technique where a part of the bone with attached tendon around
                the shoulder (Coracoid process) is transferred to the deficient
                glenoid socket. Apart from the reconstructing the glenoid
                socket, the inferior ligament is also reinforced. Thus a sling
                or seat belt effect is created preventing shoulder from
                dislocation.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What happens during Arthroscopic surgery for Shoulder
                Dislocation?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Arthroscopy or Keyhole surgery is done to visualise the shoulder
                joint. The detached capsule-labral complex is identified and
                fixed to the glenoid rim using suture anchors. If there is a
                Bony Bankart lesion it is fixed using suture anchor as well. If
                there is a further need to address the Hill Sach lesion on the
                humeral head a Remplissage procedure is performed. Remplissage
                is a French word which means ‘to fill in’ . This is done using a
                suture anchor and is performed at the same sitting. All these
                high end procedures are performed arthroscopically.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What is the cost of Arthroscopic Procedures for Shoulder
                Dislocation?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Dr Das utilises US or European imported shoulder arthroscopy
                implants with an excellent published track record. He has best
                surgical outcome with a very high patient satisfaction rate.
                This is provided at an affordable package cost which includes
                surgery, hospital stay, implants and physiotherapy. Please feel
                free to contact us for to know about the package for shoulder
                dislocation surgery.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 22,
                  fontSize: 24,
                }}
                className="listHeading"
              >
                Rotator Cuff Repair
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What is Rotator Cuff?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Shoulder is a ball and socket joint. Rotator cuff are a group of
                four muscles that come together and form a tendon which forms a
                covering of the ball of shoulder joint. It helps move and rotate
                the joint as well as stabilises the ball of the shoulder.
                Rotator cuff tears are more common after 40 years of age. It can
                result from acute trauma or repetitive overhead activity. It is
                common in sportsperson like cricket bowlers, swimmers,
                volleyball and tennis players. Patients with rotator cuff tears
                present with shoulder pain. They may also have radiating pain
                and weakness of the affected shoulder.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Is it necessary to have Rotator cuff repair surgery?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Not all tears require surgery. This depends upon the kind of
                tear. Physiotherapy and painkillers provide good relief from
                pain especially in partial thickness cuff tears. However, there
                is growing consensus among shoulder surgeons that full thickness
                tears should be treated with surgical repair. This is because
                the torn tendon retracts that is it is pulled away from its
                attachment to the humeral head by the muscle and hence, will not
                heal on its own.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What happens during Rotator cuff repair Surgery?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Dr Das uses All Arthroscopic technique to repair rotator cuff
                tear. The shoulder joint is visualised first. Then the shoulder
                blade (Acromion) is smoothened to remove any spurs and the bursa
                is cleared. Then the torn tendon is repaired back to its
                original footprint on the ball (humeral head) using suture
                anchors. The surgery usually takes one and half hours to
                compete. This can be a day case or you might have to stay in
                hospital for a few days depending upon your rehab and
                physiotherapy which is supervised by trained physiotherapists.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What is the cost of Rotator Cuff Surgery?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Dr Das utilises US or European imported implants and equipment
                with an excellent published track record. He has best surgical
                outcome with a very high patient satisfaction rate. This is
                provided at an affordable package cost which includes surgery,
                hospital stay, implants and physiotherapy. Please feel free to
                contact us for to know about the Arthroscopic rotator cuff
                repair surgery package.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 22,
                  fontSize: 24,
                }}
                className="listHeading"
              >
                Arthroscopic Capsular Release for Frozen Shoulder
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What is Frozen Shoulder?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Frozen shoulder is the term used for stiffness of the shoulder.
                It can happen following an injury and immobilisation of
                shoulder. However, in most cases there is apparently no trauma
                involved. This is also called adhesive capsulitis and is more
                common in women and Diabetic population. It is exquisitely
                painful condition and generally affects people between 30 – 60
                years of age.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Is it necessary to have Arthroscopic surgery for Frozen
                Shoulder?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Initial treatment involves shoulder exercises and physical
                therapy. This is followed by intra-articular steroid injection
                and hydrodistention. Hydrodistention involves insufflating the
                shoulder joint with saline to dilate the capsule of shoulder
                joint. If none of these measures work then Arthroscopic surgery
                in the form of capsular release is performed.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What Happens during Arthroscopic Capsular Release Surgery?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Dr Das uses All Arthroscopic technique for capsular release of
                shoulder. The shoulder joint is visualised first. Then the tight
                capsule is cut using special pencil like instrument called
                radiofrequency wand. The surgery usually takes about an hour to
                compete. This can be a day case or you might have to stay in
                hospital for a day depending upon your rehab and physiotherapy
                which is supervised by trained physiotherapists.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What is the cost of Arthroscopic Capsular Release Surgery?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Dr Das utilises US or European imported equipments. He has best
                surgical outcome with a very high patient satisfaction rate.
                This is provided at an affordable package cost which includes
                surgery, hospital stay and physiotherapy. Please feel free to
                contact us for to know about the Arthroscopic Capsular Release
                Surgery package?
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 22,
                  fontSize: 24,
                }}
                className="listHeading"
              >
                Arthroscopic Subacromial Decompression for Impingement Syndrome
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What is Impingement Syndrome?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Shoulder Impingement Syndrome is a painful condition resulting
                from rubbing of the rotator cuff tendons on the undersurface of
                the shoulder blade (acromion). This causes further swelling of
                the bursa and reduction of the subacromial space. It is common
                in young athlete and middle aged people. People who do lot of
                overhead activity like swimmers, tennis players, volleyball
                players, construction workers are more susceptible to this
                condition.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Is it necessary to have Arthroscopic Subacromial Decompression
                Surgery for Impingement Syndrome?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Initial treatment involves shoulder exercises and physical
                therapy. This is followed by intra-articular steroid injection.
                If none of these measures work, then Arthroscopic surgery in the
                form of subacromial decompression is performed.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What Happens during Arthroscopic Subacromial Decompression
                Surgery?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Dr Das uses All Arthroscopic technique for subacromial
                decompression. The shoulder joint is visualised first. Then the
                bursa above the shoulder joint is visualised and cleared using
                special pencil like instrument called radiofrequency wand. The
                shoulder blade called acromion is then flattened. The surgery
                usually takes about an hour to compete. This can be a day case
                or you might have to stay in hospital for a day depending upon
                your rehab and physiotherapy which is supervised by trained
                physiotherapists.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What is the cost of Arthroscopic Subacromial Decompression
                Surgery?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Dr Das utilises US or European imported equipments. He has best
                surgical outcome with a very high patient satisfaction rate.
                This is provided at an affordable package cost which includes
                surgery, hospital stay and physiotherapy. Please feel free to
                contact us for to know about the Arthroscopic Subacromial
                Decompression Surgery package.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 22,
                  fontSize: 24,
                }}
                className="listHeading"
              >
                Acromioclavicular Joint (ACJ) Reconstruction
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What is Acromioclavicular Joint (ACJ)?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                This is the joint which collar bone (clavicle) makes with the
                shoulder blade (acromion). This joint helps maintain the
                position of the shoulder and is very important for shoulder
                control, motion, and strength. This is injured during fall or
                trauma especially sports like cycling, cricket, football. When
                the ligaments are injured joint becomes unstable. Also ACJ is
                common joint for developing arthritis which can be very painful.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Is it necessary to have AC Joint Surgery?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                If the ACJ is painful as a result of instability then it
                requires surgery. Arthritic ACJ can be initially treated with
                steroid injection. However, if the joint remains painful then it
                requires surgery.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What Happens during ACJ Surgery?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                If the ACJ is painful as a result of arthritis then it can be
                arthroscopically excised. Dr Das uses All Arthroscopic technique
                for AC Joint Excision. The shoulder joint is visualised first.
                Then the bursa above the shoulder joint is visualised and
                cleared using special pencil like instrument called
                radiofrequency wand. The ACJ is then excised using arthroscopic
                burr. In case of Instability of ACJ, ACJ ligament reconstruction
                is done either as arthroscopic or open surgery. Ligament is
                reconstructed using patients own tendon like hamstring tendon as
                a graft or artificial ligaments. The surgery usually takes about
                one to one and a half hours to compete. This can be a day case
                or you might have to stay in hospital for a day depending upon
                your rehab and physiotherapy which is supervised by trained
                physiotherapists.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What is the cost of ACJ Surgery?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Dr Das utilises US or European imported equipments. He has best
                surgical outcome with a very high patient satisfaction rate.
                This is provided at an affordable package cost which includes
                surgery, hospital stay and physiotherapy. Please feel free to
                contact us for to know about the ACJ Surgery package.
              </p>
            </div>
            <NotFromPatna />
          </Row>
        </Container>
      </div>
    </div>
  );
}
