import React from "react";
import { Link } from "react-router-dom";
import { theme } from "../../constants/theme";
import "./navigation-header.css";

export default function NavigationHeader({ title }) {
  return (
    <div className="navigation-header-container">
      <h3
        style={{
          fontFamily: theme.fontFamily.bold,
          color: theme.color.white,
          marginBottom: 20,
        }}
        className="title-breadcrumb"
      >
        {title}
      </h3>
      <div className="breadcrumbs">
        <Link to="/" style={{ textDecoration: "none" }}>
          <p className="home-breadcrumb">Home &nbsp; /</p>
        </Link>
        <p className="active-breadcrumb">{title}</p>
      </div>
    </div>
  );
}
