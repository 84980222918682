import React from "react";
import NavigationHeader from "../../components/NavigationHeader/navigation-header";

export default function BookAppointmentView() {
  return (
    <div>
      <NavigationHeader title="Book an appointment" />
    </div>
  );
}
