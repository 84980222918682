import React from "react";
import { Container, Row } from "react-bootstrap";
import ServicesHeader from "../../../components/NavigationHeader/services-header";
import NotFromPatna from "../../../components/NotFromPatna/notFromPatna";
import { theme } from "../../../constants/theme";

export default function AnkleArthroscopyView() {
  return (
    <div>
      <ServicesHeader title="Ankle Arthroscopy" />
      <div className="service-list-container">
        <Container>
          <Row className="row-1">
            <div className="text-block">
              <h3
                className="heading-data"
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.headingText,
                  textAlign: "center",
                  marginBottom: 45,
                }}
              >
                About Ankle Arthroscopy
              </h3>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Ankle Arthroscopy is performed asleep – general anaesthesia or
                awake with spinal anaesthesia - numbing the legs. It is used to
                visualise the whole joint and treat ankle problems. Patients may
                be discharged same day or might have to stay in the hospital a
                few days depending on treatment and physiotherapy needs.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Is it necessary to have Ankle arthroscopy?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                For ankle conditions, usually a course of supervised physical
                therapy is recommended first. However, if the joint remains
                stiff, painful or locks, then it requires surgery.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What Happens during Ankle Arthroscopy?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Ankle Arthroscopy is used to visualise the whole joint. Ankle
                locking is usually caused by loose body which is
                arthroscopically removed. Cartilage injuries and osteochondral
                fractures can also be diagnosed and treated using arthroscopy.
                Bony overgrowth around the elbow called bone spur can be
                painful. This can be removed arthroscopically. The surgery
                usually takes about an hour. Patients may be discharged same day
                or might have to stay in the hospital a few days depending on
                treatment and physiotherapy needs.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What is the cost of Ankle Arthroscopy?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Dr Das utilises US or European imported equipments. He has best
                surgical outcome with a very high patient satisfaction rate.
                This is provided at an affordable package cost which includes
                surgery, hospital stay and physiotherapy. Please feel free to
                contact us to know about the ankle arthroscopy package.
              </p>
            </div>
            <NotFromPatna />
          </Row>
        </Container>
      </div>
    </div>
  );
}
