import Image1 from "../../assets/images/service1.png";
import Image2 from "../../assets/images/service2.png";
import Image3 from "../../assets/images/service3.png";
import Image4 from "../../assets/images/service4.png";
import Image5 from "../../assets/images/service5.png";
import Image6 from "../../assets/images/service6.png";
import Image7 from "../../assets/images/service7.png";
import Image8 from "../../assets/images/service8.png";
import Image9 from "../../assets/images/service9.png";
import Image10 from "../../assets/images/service10.png";
import Image11 from "../../assets/images/service11.png";
import Image12 from "../../assets/images/service12.png";

export const servicesData = [
  {
    id: 0,
    title: "Shoulder Replacement",
    subHeading: "Total Shoulder, Reverse Shoulder, Hemi Arthroplasty",
    source: Image1,
    route: "/our-services/shoulder-replacement",
  },
  {
    id: 1,
    title: "Knee Replacement",
    subHeading: "Total Knee, Uni Knee, Revision Knee Replacement",
    source: Image2,
    route: "/our-services/knee-replacement",
  },
  {
    id: 2,
    title: "Other Joint Replacement",
    subHeading: "Elbow Replacement, Hip Replacement, Finger Replacement",
    source: Image3,
    route: "/our-services/other-joint-replacement",
  },
  {
    id: 3,
    title: "Shoulder Arthroscopy",
    subHeading: "Rotator Cuff Surgery, Frozen Shoulder, Shoulder Dislocation",
    route: "/our-services/shoulder-arthroscopy",
    source: Image4,
  },
  {
    id: 4,
    title: "Knee Arthroscopy",
    subHeading: "ACL, PCL, Meniscus, Cartilage Surgery",
    source: Image5,
    route: "/our-services/knee-arthroscopy",
  },
  {
    id: 5,
    title: "Other Joint Arthroscopy",
    subHeading: "Elbow, Ankle",
    source: Image6,
    route: "/our-services/other-joint-arthroscopy",
  },
  {
    id: 6,
    title: "Trauma Surgery",
    subHeading:
      "Periprosthetic Joint Fracture Management, Complex and Periarticular Fracture Management",
    source: Image7,
    route: "/our-services/trauma-surgery",
  },
  {
    id: 7,
    title: "ACL Surgery",
    subHeading: "",
    source: Image8,
    route: "/our-services/acl-surgery",
  },
  {
    id: 8,
    title: "Shoulder Dislocation",
    subHeading: "",
    source: Image9,
    route: "/our-services/shoulder-dislocation",
  },
];

export const allServicesData = [
  {
    id: 0,
    title: "Shoulder Replacement",
    subHeading: "Total Shoulder, Reverse Shoulder, Hemi Arthroplasty",
    source: Image1,
    route: "/our-services/shoulder-replacement",
  },
  {
    id: 1,
    title: "Knee Replacement",
    subHeading: "Total Knee, Uni Knee, Revision Knee Replacement",
    source: Image2,
    route: "/our-services/knee-replacement",
  },
  {
    id: 9,
    title: "Elbow Replacement",
    subHeading: "",
    source: Image3,
    route: "/our-services/elbow-replacement",
  },
  {
    id: 10,
    title: "Hip Replacement",
    subHeading: "",
    source: Image10,
    route: "/our-services/hip-replacement",
  },
  {
    id: 11,
    title: "Finger Joint Replacement",
    subHeading: "",
    source: Image11,
    route: "/our-services/finger-joint-replacement",
  },
  {
    id: 3,
    title: "Shoulder Arthroscopy",
    subHeading: "Rotator Cuff Surgery, Frozen Shoulder, Shoulder Dislocation",
    route: "/our-services/shoulder-arthroscopy",
    source: Image4,
  },
  {
    id: 4,
    title: "Knee Arthroscopy",
    subHeading: "ACL, PCL, Meniscus, Cartilage Surgery",
    source: Image5,
    route: "/our-services/knee-arthroscopy",
  },
  {
    id: 5,
    title: "Elbow Arthroscopy",
    subHeading: "",
    source: Image12,
    route: "/our-services/elbow-arthroscopy",
  },
  {
    id: 12,
    title: "Ankle Arthroscopy",
    subHeading: "",
    source: Image6,
    route: "/our-services/ankle-arthroscopy",
  },
  {
    id: 6,
    title: "Trauma Surgery",
    subHeading:
      "Periprosthetic Joint Fracture Management, Complex and Periarticular Fracture Management",
    source: Image7,
    route: "/our-services/trauma-surgery",
  },
  {
    id: 7,
    title: "ACL Surgery",
    subHeading: "",
    source: Image8,
    route: "/our-services/acl-surgery",
  },
  {
    id: 8,
    title: "Shoulder Dislocation",
    subHeading: "",
    source: Image9,
    route: "/our-services/shoulder-dislocation",
  },
];
