import React from "react";
import { Container, Row } from "react-bootstrap";
import ServicesHeader from "../../../components/NavigationHeader/services-header";
import NotFromPatna from "../../../components/NotFromPatna/notFromPatna";
import { theme } from "../../../constants/theme";

export default function ShoulderReplacementView() {
  return (
    <div>
      <ServicesHeader title="Shoulder Replacement" />
      <div className="service-list-container">
        <Container>
          <Row className="row-1">
            <div className="text-block">
              <h3
                className="heading-data"
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.headingText,
                  textAlign: "center",
                  marginBottom: 45,
                }}
              >
                About Shoulder Replacement
              </h3>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Shoulder Replacement surgery replaces the worn out cartilage of
                the shoulder joint either as a result of arthritis or injury.
                This is also called Shoulder Arthroplasty. Articular cartilage
                is the smooth shock absorbing lining of the bones of the
                Shoulder joint.
              </p>
              <p
                className="listHeading"
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
              >
                Types of Shoulder Replacement:
              </p>
              <ul>
                <li
                  style={{
                    fontFamily: theme.fontFamily.light,
                    color: theme.color.lightText,
                    marginBottom: 20,
                    textAlign: "justify",
                  }}
                  className="listItem"
                >
                  <span
                    style={{
                      fontFamily: theme.fontFamily.regular,
                      color: theme.color.lightText,
                      textAlign: "justify",
                    }}
                  >
                    Anatomic Total Shoulder Replacement:
                  </span>{" "}
                  Shoulder is a ball and socket joint. The key muscles that move
                  it are called the rotator cuff surrounded by deltoid which
                  provides rounded contour to the shoulder. This is performed
                  when the cartilage of both the ball and socket are worn out
                  and rotator cuff tendon is intact. The socket is replaced by
                  high quality plastic and ball with highly polished metal.
                </li>
                <li
                  style={{
                    fontFamily: theme.fontFamily.light,
                    color: theme.color.lightText,
                    marginBottom: 20,
                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontFamily: theme.fontFamily.regular,
                      color: theme.color.lightText,
                      textAlign: "justify",
                    }}
                  >
                    Anatomic Total Shoulder Replacement:
                  </span>{" "}
                  Shoulder is a ball and socket joint. The key muscles that move
                  it are called the rotator cuff surrounded by deltoid which
                  provides rounded contour to the shoulder. This is performed
                  when the cartilage of both the ball and socket are worn out
                  and rotator cuff tendon is intact. The socket is replaced by
                  high quality plastic and ball with highly polished metal.
                </li>
                <li
                  style={{
                    fontFamily: theme.fontFamily.light,
                    color: theme.color.lightText,
                    marginBottom: 20,
                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontFamily: theme.fontFamily.regular,
                      color: theme.color.lightText,
                      textAlign: "justify",
                    }}
                  >
                    Reverse Geometry Shoulder Replacement:
                  </span>{" "}
                  This is performed for advanced shoulder arthritis or fracture
                  where rotator cuff muscles are not functioning. It is called
                  reverse geometry because the shoulder socket is replaced with
                  a metal ball and shoulder ball with durable plastic held in
                  metal socket/ tray. This increases the efficiency of deltoid
                  muscles in the absence of functioning rotator cuff muscles.
                </li>
                <li
                  style={{
                    fontFamily: theme.fontFamily.light,
                    color: theme.color.lightText,
                    marginBottom: 20,
                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontFamily: theme.fontFamily.regular,
                      color: theme.color.lightText,
                      textAlign: "justify",
                    }}
                  >
                    Shoulder Resurfacing:
                  </span>{" "}
                  Resurfacing is a type of shoulder arthroplasty that involves
                  replacing the humeral joint surface with a metal covering, or
                  cap, thus preserving the bone of the proximal part of the
                  humerus. Very few patients are suitable for this surgery.
                </li>
                <li
                  style={{
                    fontFamily: theme.fontFamily.light,
                    color: theme.color.lightText,
                    marginBottom: 20,
                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontFamily: theme.fontFamily.regular,
                      color: theme.color.lightText,
                      textAlign: "justify",
                    }}
                  >
                    Hemiarthroplasty/ Partial shoulder replacement:
                  </span>{" "}
                  This is preferred in young patients with complex non-fixable
                  upper humerus fractures. The shoulder ball is replaced with a
                  metal ball with a stem.
                </li>
              </ul>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                Is it necessary to have a Shoulder replacement?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Advanced osteoarthritis or Rheumatoid arthritis of shoulder is a
                painful condition limiting the ability of patients to move the
                shoulder and perform activities of daily living like lifting.
                This leads to deterioration in quality of life. Shoulder
                replacement is also needed in complex shoulder fractures where
                fracture cannot be fixed.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What happens during Shoulder Replacement Surgery?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                The procedure is performed with patient asleep – general
                anaesthesia. This will be discussed by your anesthesiologist.
                The procedure usually takes one or two hours. Dr Das will remove
                the damaged cartilage and bone and then position the new metal
                +/- plastic joint surfaces to restore the function of your
                shoulder. He exclusively utilises US or European imported
                shoulder replacement implants with an excellent published track
                record. You might have to stay in hospital for a few days
                depending upon your rehab and physiotherapy which is supervised
                by trained physiotherapists.
              </p>
              <p
                style={{
                  fontFamily: theme.fontFamily.bold,
                  color: theme.color.black,
                  marginBottom: 12,
                  marginTop: 16,
                  fontSize: 16,
                }}
                className="listHeading"
              >
                What is the cost of shoulder replacement Surgery?
              </p>
              <p
                className="desc-text"
                style={{
                  fontFamily: theme.fontFamily.light,
                  color: theme.color.lightText,
                  marginBottom: 12,
                }}
              >
                Dr Das exclusively utilises US or European imported shoulder
                replacement implants with an excellent published track record.
                He has best surgical outcome with a very high patient
                satisfaction rate. This is provided at an affordable package
                cost which includes surgery, hospital stay, implants and
                physiotherapy. Please feel free to contact us for to know about
                the shoulder replacement package.
              </p>
            </div>
            <NotFromPatna />
          </Row>
        </Container>
      </div>
    </div>
  );
}
